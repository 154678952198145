import { Box } from "@mui/material";

interface Props {
  color?: string;
}

export default function ({ color }: Props) {
  return (
    <Box
      sx={{
        height: "36px",
        width: "36px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="36" height="36" rx="6" fill="#7E2AEA" />
        <path
          d="M12.2045 26.495C13.6982 26.495 14.9091 25.2842 14.9091 23.7905C14.9091 22.2968 13.6982 21.0859 12.2045 21.0859C10.7109 21.0859 9.5 22.2968 9.5 23.7905C9.5 25.2842 10.7109 26.495 12.2045 26.495Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.7944 14.9091C25.2881 14.9091 26.4989 13.6982 26.4989 12.2045C26.4989 10.7109 25.2881 9.5 23.7944 9.5C22.3007 9.5 21.0898 10.7109 21.0898 12.2045C21.0898 13.6982 22.3007 14.9091 23.7944 14.9091Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.2045 14.9091C13.6982 14.9091 14.9091 13.6982 14.9091 12.2045C14.9091 10.7109 13.6982 9.5 12.2045 9.5C10.7109 9.5 9.5 10.7109 9.5 12.2045C9.5 13.6982 10.7109 14.9091 12.2045 14.9091Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.2031 21.0959V20.3232C12.2019 20.0184 12.261 19.7163 12.377 19.4345C12.4931 19.1527 12.6638 18.8966 12.8793 18.6811C13.0948 18.4656 13.3509 18.2949 13.6327 18.1788C13.9145 18.0628 14.2165 18.0037 14.5213 18.005H21.4759C21.7807 18.0062 22.0827 17.9472 22.3645 17.8311C22.6463 17.7151 22.9024 17.5444 23.1179 17.3288C23.3334 17.1133 23.5042 16.8573 23.6202 16.5754C23.7362 16.2936 23.7953 15.9916 23.7941 15.6868V14.9141"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.2031 14.9141V21.0959"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
}

import { Box } from "@mui/material";

interface Props {
  color?: string;
}

export default function ({ color }: Props) {
  return (
    <Box
      sx={{
        height: "36px",
        width: "36px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="36" height="36" rx="6" fill="#7E2AEA" />
        <path
          d="M25.8191 22.2188H10.2095C9.81766 22.2188 9.5 22.3035 9.5 22.408V25.8137C9.5 25.9182 9.81766 26.0029 10.2095 26.0029H25.8191C26.2109 26.0029 26.5286 25.9182 26.5286 25.8137V22.408C26.5286 22.3035 26.2109 22.2188 25.8191 22.2188Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="11.4443" cy="24.167" r="0.807589" fill="white" />
        <path
          d="M25.8191 16.1016H10.2095C9.81766 16.1016 9.5 16.1863 9.5 16.2908V19.6965C9.5 19.801 9.81766 19.8857 10.2095 19.8857H25.8191C26.2109 19.8857 26.5286 19.801 26.5286 19.6965V16.2908C26.5286 16.1863 26.2109 16.1016 25.8191 16.1016Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="11.4443" cy="18.0498" r="0.807589" fill="white" />
        <path
          d="M25.8191 10H10.2095C9.81766 10 9.5 10.0847 9.5 10.1892V13.5949C9.5 13.6994 9.81766 13.7841 10.2095 13.7841H25.8191C26.2109 13.7841 26.5286 13.6994 26.5286 13.5949V10.1892C26.5286 10.0847 26.2109 10 25.8191 10Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="11.4443" cy="11.9482" r="0.807589" fill="white" />
      </svg>
    </Box>
  );
}

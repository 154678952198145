import { Box } from "@mui/material";

interface Props {
  color?: string;
}

export default function ({ color }: Props) {
  return (
    <Box
      sx={{
        height: "59px",
        width: "59px",
        display: "flex",
        borderRadius: "6px",
        background: "#5A1EA8",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <svg
        width="39"
        height="39"
        viewBox="0 0 39 39"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="UserCircle">
          <path
            id="Vector"
            d="M19.5 24.375C22.8655 24.375 25.5938 21.6467 25.5938 18.2812C25.5938 14.9158 22.8655 12.1875 19.5 12.1875C16.1345 12.1875 13.4062 14.9158 13.4062 18.2812C13.4062 21.6467 16.1345 24.375 19.5 24.375Z"
            stroke="white"
            strokeWidth="1.5"
            strokeMiterlimit="10"
          />
          <path
            id="Vector_2"
            d="M9.71875 30.3773C10.6357 28.5711 12.0349 27.0542 13.7613 25.9946C15.4876 24.9349 17.4736 24.374 19.4992 24.374C21.5248 24.374 23.5109 24.9349 25.2372 25.9946C26.9635 27.0542 28.3627 28.5711 29.2797 30.3773"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_3"
            d="M34.0184 17.7481C34.0923 18.3291 34.1279 18.9143 34.125 19.5C34.125 22.3926 33.2673 25.2202 31.6602 27.6252C30.0532 30.0303 27.7691 31.9048 25.0968 33.0118C22.4244 34.1187 19.4838 34.4083 16.6468 33.844C13.8098 33.2797 11.2039 31.8868 9.15857 29.8415C7.11323 27.7961 5.72033 25.1902 5.15602 22.3532C4.59171 19.5162 4.88134 16.5756 5.98827 13.9033C7.0952 11.2309 8.96972 8.94679 11.3748 7.33978C13.7799 5.73276 16.6075 4.87502 19.5 4.87502C19.9527 4.87216 20.4052 4.8925 20.8559 4.93596"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_4"
            d="M30.6413 6.18713C30.9363 6.06408 31.2532 6.00067 31.5735 6.00064C31.8934 6.00091 32.2109 6.06444 32.5056 6.18747C32.8003 6.31051 33.067 6.49057 33.29 6.71703C33.7454 7.18125 34 7.80218 34 8.44844C34 9.09471 33.7454 9.71563 33.29 10.1799L30.2104 13.2843C29.8192 13.6787 29.1817 13.6787 28.7905 13.2843L25.7099 10.179C25.2546 9.71475 25 9.09382 25 8.44755C25 7.80129 25.2546 7.18036 25.7099 6.71614C25.9332 6.48951 26.2003 6.30938 26.4953 6.18638C26.7902 6.06337 27.1072 6 27.4274 6C27.7476 6 28.0646 6.06337 28.3596 6.18638C28.6546 6.30938 28.9216 6.48951 29.1449 6.71614L29.5004 7.07611L29.856 6.71703C30.0793 6.49035 30.3463 6.31017 30.6413 6.18713Z"
            fill="white"
          />
        </g>
      </svg>
    </Box>
  );
}

import {
    Box,
    Button,
    SxProps,
    Theme,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import SectionWrapper from "@ui_kit/SectionWrapper";
import React from "react";
import HeaderFull from "@ui_kit/Header/HeaderFull";
import QuizCardDummy from "../QuizCardDummy";
import AvailablePrivilegeDummy from "../AvailablePrivilegeDummy";


export default function ListPageDummy() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(500));


    return (
        <>
            <HeaderFull isRequest={false} />
                <SectionWrapper
                    maxWidth="lg"
                    sx={{ padding: isMobile ? "0 16px" : "20px" }}
                >
                </SectionWrapper>
        </>
    );
}

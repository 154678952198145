export const BackButtonIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M7.86747 18C8.26104 18 9.9253 18 13.4337 18C17.8193 18 19 14.703 19 13.2194C19 11.7358 17.8193 8.93333 13.4337 8.93333C10.1773 8.93333 6.59726 8.93333 5 8.93333M5 8.93333L7.86747 6M5 8.93333L7.86747 11.8182"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

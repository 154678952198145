import { FC, SVGProps } from "react";

export const ArrowDown: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    {...props}
    width="11"
    height="7"
    viewBox="0 0 11 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.1875 1.125L5.5 5.8125L0.8125 1.125"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

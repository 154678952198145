export const replaceEmptyLinesToSpace = <T = unknown>(object: T): T => {
  if (Array.isArray(object)) {
    return object.map(replaceEmptyLinesToSpace) as T;
  }

  if (!object || typeof object !== "object") {
    return object;
  }

  const result: Record<string, unknown> = {};

  for (const [key, value] of Object.entries(object)) {
    if (typeof value === "string") {
      if (value === "") {
        result[key] = " ";
      } else {
        result[key] = value;
      }

      continue;
    }

    if (typeof value === "object") {
      result[key] = replaceEmptyLinesToSpace(value);

      continue;
    }

    result[key] = value;
  }

  return result as T;
};

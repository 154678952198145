import {
  FormControl,
  IconButton,
  InputLabel,
  SxProps,
  TextField,
  TextFieldProps,
  Theme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import * as React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

interface Props {
  id: string;
  label?: string;
  bold?: boolean;
  gap?: string;
  color?: string;
  FormInputSx?: SxProps<Theme>;
  TextfieldProps: TextFieldProps;
  onChange: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void;
}

export default function PasswordInput({
  id,
  label,
  bold = false,
  gap = "10px",
  onChange,
  TextfieldProps,
  color,
  FormInputSx,
}: Props) {
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up("md"));

  const labelFont = upMd
    ? bold
      ? theme.typography.p1
      : { ...theme.typography.body1, fontWeight: 500 }
    : theme.typography.body2;

  const placeholderFont = upMd
    ? undefined
    : { fontWeight: 400, fontSize: "16px", lineHeight: "19px" };

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  return (
    <FormControl
      fullWidth
      variant="standard"
      sx={{
        gap,
        ...FormInputSx,
        position: "relative",
      }}
    >
      <InputLabel
        shrink
        htmlFor={id}
        sx={{
          position: "inherit",
          color: "black",
          transform: "none",
          ...labelFont,
        }}
      >
        {label}
      </InputLabel>
      <TextField
        {...TextfieldProps}
        fullWidth
        id={id}
        sx={{
          "& .MuiInputBase-root": {
            height: "48px",
            borderRadius: "8px",
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                sx={{
                  position: "absolute",
                  right: "15px",
                  top: "5px",
                }}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
          sx: {
            padding: "0px",
            border: "1px solid #9A9AAF",
            backgroundColor: color,
            borderRadius: "8px",
            height: "48px",
            color: "black",
            ...placeholderFont,
            "& .MuiInputBase-input": {
              boxSizing: "border-box",
              height: "100%",
              padding: "14px",
            },
          },
        }}
        onChange={onChange}
        type={showPassword ? "text" : "password"}
      />
    </FormControl>
  );
}

import { useTheme } from "@mui/material";

interface Props {
  width?: number;
}

export default function PenaLogo({ width }: Props) {
  const theme = useTheme();

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.39844 2.39999L21.5984 21.6M2.39844 21.6L21.5984 2.39999"
        stroke="black"
      />
    </svg>
  );
}

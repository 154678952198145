import { create } from "zustand";
import { sendContactFormRequest } from "../api/contactForm";
import { parseAxiosError } from "@utils/parse-error";

interface ContactFormStore {
  isModalOpen: boolean;
  isSubmitDisabled: boolean;
  mail: string;
  phoneNumberField: string;
  telegramField: string;
  whatsappField: string;
}

const initialState: ContactFormStore = {
  isModalOpen: false,
  isSubmitDisabled: false,
  mail: "",
  phoneNumberField: "",
  telegramField: "",
  whatsappField: "",
};

export const useContactFormStore = create<ContactFormStore>()(
  (set, get) => initialState,
);

export const setIsContactFormOpen = (
  isModalOpen: ContactFormStore["isModalOpen"],
) => useContactFormStore.setState({ isModalOpen });
export const setContactFormMailField = (mail: ContactFormStore["mail"]) =>
  useContactFormStore.setState({ mail });
export const setContactFormTelegramField = (
  telegramField: ContactFormStore["telegramField"],
) => useContactFormStore.setState({ telegramField });
export const setIsSubmitDisabled = (
  isSubmitDisabled: ContactFormStore["isSubmitDisabled"],
) => useContactFormStore.setState({ isSubmitDisabled });
export const setContactFormPhoneNumberField = (
  phoneNumberField: ContactFormStore["phoneNumberField"],
) => {
  if (/^\+?\d*$/.test(phoneNumberField))
    useContactFormStore.setState({ phoneNumberField });
};
export const setContactFormWhatsappField = (
  whatsappField: ContactFormStore["whatsappField"],
) => {
  if (/^\+?\d*$/.test(whatsappField))
    useContactFormStore.setState({ whatsappField });
};

export const sendContactForm = async (): Promise<string | null> => {
  const { mail, phoneNumberField, telegramField, whatsappField } =
    useContactFormStore.getState();

  if (!mail) return "Почта не указана";
  if (!mail.includes("@")) return "Почта некорректна";
  let contact = "Опросник, предрегистрация";

  try {
    useContactFormStore.setState({ isSubmitDisabled: true });
    const [_, error, status] = await sendContactFormRequest({
      contact,
      whoami: mail,
    });

    if (status !== 200) {
      throw new Error(error);
    }

    useContactFormStore.setState({ ...initialState });

    return "Данные отправлены";
  } catch (error: any) {
    useContactFormStore.setState({ isSubmitDisabled: false });
    const [errorMessage] = parseAxiosError(error);

    return errorMessage;
  }
};

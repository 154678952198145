import {
  Box,
  Dialog,
  IconButton,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import InputTextfield from "@ui_kit/InputTextfield";
import PenaLogo from "../Landing/images/icons/QuizLogo";
import { enqueueSnackbar } from "notistack";
import { object, string } from "yup";
import { useEffect, useState } from "react";
import { useUserStore } from "@root/user";

import { makeRequest } from "@api/makeRequest";
import { setAuthToken } from "@frontend/kitui";
import { parseAxiosError } from "@utils/parse-error";
import { recoverUser } from "@api/user";
interface Values {
  password: string;
}

const initialValues: Values = {
  password: "",
};

const validationSchema = object({
  password: string()
    .min(8, "Минимум 8 символов")
    .matches(
      /^[.,:;\-_+!&()*<>\[\]\{\}`@"#$\%\^\=?\d\w]+$/,
      "Некорректные символы",
    )
    .required("Поле обязательно"),
});

export default function RecoverPassword() {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(true);
  const [tokenUser, setTokenUser] = useState<string>("");
  const user = useUserStore((state) => state.user);
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down(450));

  const navigate = useNavigate();
  const location = useLocation();
  const formik = useFormik<Values>({
    initialValues,
    validationSchema,
    onSubmit: async (values, formikHelpers) => {
      if (tokenUser) {
        setAuthToken(tokenUser || "");
        const [_, recoverError] = await recoverUser(values.password);

        if (recoverError) {
          setAuthToken("");
          enqueueSnackbar(
            `Извините, произошла ошибка, попробуйте повторить позже. ${recoverError}`,
          );
        } else {
          setIsDialogOpen(false);
          navigate("/");
          enqueueSnackbar("Пароль успешно сменён");
        }
      } else {
        enqueueSnackbar("Неверный url-адрес");
      }
    },
  });
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const authToken = params.get("auth");
    setTokenUser(authToken);

    history.pushState(null, document.title, "/changepwd");
    return () => {
      setAuthToken("");
    };
  }, []);

  function handleClose() {
    setIsDialogOpen(false);
    setTimeout(() => navigate("/"), theme.transitions.duration.leavingScreen);
  }

  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: isMobile ? "100%" : "600px",
          maxWidth: isMobile ? "100%" : "600px",
          margin: isMobile ? "0" : "32px",
          maxHeight: isMobile ? "100%" : "-moz-initial",
          height: isMobile ? "100%" : "-moz-initial",
        },
      }}
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: "rgb(0 0 0 / 0.7)",
          },
        },
      }}
    >
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        noValidate
        sx={{
          height: isMobile ? "100%" : "-moz-initial",
          position: "relative",
          backgroundColor: "white",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          p: upMd ? "50px" : "18px",
          pb: upMd ? "40px" : "30px",
          gap: "15px",
          borderRadius: "12px",
          "& .MuiFormHelperText-root.Mui-error, & .MuiFormHelperText-root.Mui-error.MuiFormHelperText-filled":
            {
              position: "absolute",
              top: "46px",
              margin: "0",
            },
        }}
      >
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: "7px",
            top: "7px",
          }}
        >
          <CloseIcon sx={{ transform: "scale(1.5)" }} />
        </IconButton>
        <Box>
          <PenaLogo width={upMd ? 233 : 196} color="black" />
        </Box>
        <Typography
          sx={{
            color: "#4D4D4D",
            mt: "5px",
            mb: upMd ? "10px" : "33px",
          }}
        >
          Введите новый пароль
        </Typography>
        <InputTextfield
          TextfieldProps={{
            value: formik.values.password,
            placeholder: "введите пароль",
            onBlur: formik.handleBlur,
            error: formik.touched.password && Boolean(formik.errors.password),
            helperText: formik.touched.password && formik.errors.password,
          }}
          onChange={formik.handleChange}
          color="#F2F3F7"
          id="password"
          label="Новый пароль"
          gap={upMd ? "10px" : "10px"}
        />
        <Button
          variant="contained"
          fullWidth
          type="submit"
          disabled={formik.isSubmitting}
          sx={{
            py: "12px",
            "&:hover": {
              backgroundColor: "#581CA7",
            },
            "&:active": {
              color: "white",
              backgroundColor: "black",
            },
          }}
        >
          Восстановить
        </Button>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
            mt: "auto",
          }}
        ></Box>
      </Box>
    </Dialog>
  );
}

import { Box } from "@mui/material";

interface Props {
  color?: string;
}

export default function ({ color }: Props) {
  return (
    <Box
      sx={{
        height: "36px",
        width: "36px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="36" height="36" rx="6" fill="#7E2AEA" />
        <path
          d="M18 26C18.7963 25.9995 19.5881 25.8815 20.35 25.65L26 18C26 16.4178 25.5308 14.871 24.6518 13.5554C23.7727 12.2398 22.5233 11.2145 21.0615 10.609C19.5997 10.0035 17.9911 9.84504 16.4393 10.1537C14.8874 10.4624 13.462 11.2243 12.3431 12.3431C11.2243 13.462 10.4624 14.8874 10.1537 16.4393C9.84504 17.9911 10.0035 19.5997 10.609 21.0615C11.2145 22.5233 12.2398 23.7727 13.5554 24.6518C14.871 25.5308 16.4178 26 18 26Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 17.3359C15.5523 17.3359 16 16.8882 16 16.3359C16 15.7837 15.5523 15.3359 15 15.3359C14.4477 15.3359 14 15.7837 14 16.3359C14 16.8882 14.4477 17.3359 15 17.3359Z"
          fill="white"
        />
        <path
          d="M21 17.3359C21.5523 17.3359 22 16.8882 22 16.3359C22 15.7837 21.5523 15.3359 21 15.3359C20.4477 15.3359 20 15.7837 20 16.3359C20 16.8882 20.4477 17.3359 21 17.3359Z"
          fill="white"
        />
        <path
          d="M21.4685 20C21.1161 20.6072 20.6104 21.1111 20.002 21.4614C19.3936 21.8117 18.7039 21.9961 18.0018 21.9961C17.2998 21.9961 16.6101 21.8117 16.0017 21.4614C15.3933 21.1111 14.8876 20.6072 14.5352 20"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
}

import { Box } from "@mui/material";

export const DurationTariffIcon = () => {
  return (
    <Box>
      <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="36" height="36" rx="6" fill="#2B2C39" />
        <circle cx="18" cy="18" r="10" stroke="#944FEE" strokeWidth="1.5" />
        <path
          d="M18 14V17.7324C18 17.8996 18.0836 18.0557 18.2226 18.1484L21 20"
          stroke="#944FEE"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    </Box>
  );
};

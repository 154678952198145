import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import { FC } from "react";
import { DurationTariffIcon } from "@/pages/Landing/Tariffs/TariffCard/icons/DurationTariffIcon";
import { TrialTariffIcon } from "@/pages/Landing/Tariffs/TariffCard/icons/TrialTariffIcon";
import { RequestsTariffIcon } from "@/pages/Landing/Tariffs/TariffCard/icons/RequestsTariffIcon";
import { Link, useLocation } from "react-router-dom";

type TariffCardProps = {
  type: "requests" | "duration" | "trial";
  name: string;
  description: string;
  actualPrice: string;
  oldPrice?: string;
  discount?: string;
};

const icons = {
  requests: <RequestsTariffIcon />,
  duration: <DurationTariffIcon />,
  trial: <TrialTariffIcon />,
};

export const TariffCard: FC<TariffCardProps> = ({ type, name, actualPrice, oldPrice, discount, description }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  const isTablet = useMediaQuery(theme.breakpoints.down(1140));

  const location = useLocation();

  return (
    <Box
      sx={{
        width: isMobile ? "321px" : "360px",
        height: "313px",
        background: type === "trial" ? "#7E2AEA" : "#333646",
        borderRadius: "12px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex", padding: "20px", justifyContent: "space-between", alignItems: "center" }}>
        <Box sx={{ display: "flex", gap: "15px" }}>
          {icons[type]}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "#7E2AEA",
              padding: "8px 10px",
              borderRadius: "8px",
              height: "fit-content",
            }}
          >
            {discount && <Typography sx={{ color: "#FFF", lineHeight: "1" }}>-{discount}%</Typography>}
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: "15px" }}>
          {oldPrice && (
            <Typography
              sx={{
                color: "#9A9AAF",
                fontSize: "16px",
                textDecoration: "line-through",
                textDecorationColor: "#FB5607",
                textDecorationThickness: "1px",
              }}
            >
              {oldPrice} ₽
            </Typography>
          )}
          {actualPrice && (
            <Typography
              sx={{
                color: "#FFF",
                fontSize: "20px",
              }}
            >
              {actualPrice} ₽
            </Typography>
          )}
        </Box>
      </Box>
      <Box sx={{ marginTop: "5px", padding: "0 20px", display: "flex", flexDirection: "column", gap: "10px" }}>
        {name && <Typography sx={{ color: "#FFF", fontSize: "24px" }}>{name}</Typography>}
        {description && <Typography sx={{ color: type === "trial" ? "#EEEFF4" : "#9A9AAF" }}>{description}</Typography>}
      </Box>
      <Box
        sx={{
          marginTop: "auto",
          height: "64px",
          background: type === "trial" ? "#5A1EA8" : "rgba(154, 154, 175, 0.5)",
          borderRadius: "0 0 12px 12px",
        }}
      >
        <Button
          component={Link}
          to={"/signin"}
          state={{ backgroundLocation: location }}
          sx={{
            color: "#FFF",
            width: "100%",
            height: "100%",
          }}
        >
          Выбрать
        </Button>
      </Box>
    </Box>
  );
};

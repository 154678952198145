import { QUIZ_QUESTION_BASE } from "./base";

import type { QuizQuestionVarImg } from "@frontend/squzanswerer";
import { nanoid } from "nanoid";

export const QUIZ_QUESTION_VARIMG: Omit<QuizQuestionVarImg, "id" | "backendId"> = {
  ...QUIZ_QUESTION_BASE,
  type: "varimg",
  content: {
    ...QUIZ_QUESTION_BASE.content,
    own: false,
    innerNameCheck: false,
    innerName: "",
    required: false,
    variants: [
      {
        id: nanoid(),
        answer: "",
        hints: "",
        extendedText: "",
        originalImageUrl: "",
      },
    ],
    largeCheck: false,
    replText: "",
  },
};

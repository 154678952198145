import { QUIZ_QUESTION_BASE } from "./base";

import type { QuizQuestionEmoji } from "@frontend/squzanswerer";
import { nanoid } from "nanoid";

export const QUIZ_QUESTION_EMOJI: Omit<QuizQuestionEmoji, "id" | "backendId"> = {
  ...QUIZ_QUESTION_BASE,
  type: "emoji",
  content: {
    ...QUIZ_QUESTION_BASE.content,
    multi: false,
    own: false,
    innerNameCheck: false,
    innerName: "",
    required: false,
    variants: [
      {
        id: nanoid(),
        answer: "",
        extendedText: "",
        hints: "",
        originalImageUrl: "",
      },
    ],
  },
};

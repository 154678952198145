import { useUiTools } from "@root/uiTools/store";
import { updateModalInfoWhyCantCreate } from "@root/uiTools/actions";
import { Button } from "@mui/material";
import { useCurrentQuiz } from "@root/quizes/hooks";

export const ButtonTestPublication = () => {
  const { whyCantCreatePublic, canCreatePublic } = useUiTools();
  const quizId = useCurrentQuiz()?.qid;

  if (canCreatePublic) {
    return (
      <a
        href={`/view/${quizId}`}
        target="_blank"
        rel="noreferrer"
        style={{ textDecoration: "none" }}
      >
        <Button
          variant="contained"
          sx={{
            fontSize: "14px",
            whiteSpace: "nowrap",
            lineHeight: "18px",
            height: "34px",
            width: "146px",
          }}
        >
          Тестовый просмотр
        </Button>
      </a>
    );
  } else {
    return (
      <Button
        variant="contained"
        sx={{
          fontSize: "14px",
          lineHeight: "18px",
          height: "34px",
          whiteSpace: "nowrap",
          minWidth: "146px",
        }}
        onClick={() =>
          Object.keys(whyCantCreatePublic).length === 0
            ? () => {}
            : updateModalInfoWhyCantCreate(true)
        }
      >
        Тестовый просмотр
      </Button>
    );
  }
};

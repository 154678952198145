import { Box } from "@mui/material";

interface Props {
  height: string;
  width: string;
  color: string;
}

export default function GearIcon({ height, width, color }: Props) {
  return (
    <Box
      sx={{
        borderRadius: "6px",
        height,
        width,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="35"
        viewBox="0 0 35 35"
        fill="none"
      >
        <path
          d="M17.5 24.0625C21.1244 24.0625 24.0625 21.1244 24.0625 17.5C24.0625 13.8756 21.1244 10.9375 17.5 10.9375C13.8756 10.9375 10.9375 13.8756 10.9375 17.5C10.9375 21.1244 13.8756 24.0625 17.5 24.0625Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.9884 11.0332C27.3176 11.5044 27.6057 12.003 27.8498 12.5234L31.3908 14.4922C31.8329 16.4725 31.8376 18.5255 31.4044 20.5078L27.8498 22.4766C27.6057 22.997 27.3176 23.4956 26.9884 23.9668L27.0568 28.0273C25.5561 29.3949 23.7799 30.4251 21.8478 31.0488L18.3615 28.957C17.788 28.9981 17.2123 28.9981 16.6388 28.957L13.1662 31.0352C11.2279 30.4228 9.4459 29.3965 7.94351 28.0273L8.01187 23.9805C7.68548 23.5027 7.39747 22.9998 7.15054 22.4766L3.60952 20.5078C3.16737 18.5275 3.16271 16.4745 3.59585 14.4922L7.15054 12.5234C7.39456 12.003 7.68272 11.5044 8.01187 11.0332L7.94351 6.97266C9.4442 5.60515 11.2204 4.57488 13.1525 3.95117L16.6388 6.04297C17.2123 6.00195 17.788 6.00195 18.3615 6.04297L21.8341 3.96484C23.7724 4.5772 25.5544 5.60349 27.0568 6.97266L26.9884 11.0332Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
}

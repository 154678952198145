import {
  FormControlLabel,
  Checkbox,
  useTheme,
  Box,
  useMediaQuery,
} from "@mui/material";
import React from "react";

import CheckboxIcon from "@icons/Checkbox";

import type { SxProps } from "@mui/material";

interface Props {
  label: string;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  sx?: SxProps;
  dataCy?: string;
  colorIcon?: string;
}

export default function CustomCheckbox({
  label,
  handleChange,
  checked,
  sx,
  dataCy,
  colorIcon,
}: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(790));

  return (
    <FormControlLabel
      control={
        <Checkbox
          sx={{ padding: "0px 13px 1px 11px" }}
          disableRipple
          icon={<CheckboxIcon />}
          checkedIcon={<CheckboxIcon checked color={colorIcon} />}
          onChange={handleChange}
          checked={checked}
          data-cy={dataCy}
        />
      }
      label={label}
      sx={{
        color: "#9A9AAF",
        height: "26px",
        ...sx,
      }}
    />
  );
}

import moment from "moment";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

interface CartStore {
    notEnoughMoneyAmount: number;
    siteReadyPayCart: Record<string, string> | null;
}
const initialState: CartStore = {
    notEnoughMoneyAmount: 0,
    siteReadyPayCart: null
}

//Была попытка оплатить корзину. Тут записанна недостающая сумма.
export const useNotEnoughMoneyAmount = create<CartStore>()(
    devtools(
        (get, set) => initialState,
        {
            name: "notEnoughMoneyAmount",
            enabled: process.env.NODE_ENV === "development",
            trace: true,
            actionsBlacklist: "rejected",
        }
    )
);

export const setNotEnoughMoneyAmount = (amount: number) => useNotEnoughMoneyAmount.setState({ notEnoughMoneyAmount: amount });

export const setSiteReadyPayCart = (flag: Record<string, string> | null) => useNotEnoughMoneyAmount.setState({ siteReadyPayCart: flag });
export const startPayCartProcess = (userId: string) => setSiteReadyPayCart({ [userId]: moment().add(20, 'minutes').format("X") });
export const cancelPayCartProcess = () => setSiteReadyPayCart(null);
export const calcTimeOfReadyPayCart = (deadline: string) => {
    const ready = Number(deadline) > Number(moment().format("X"))
    if (!ready) {
        cancelPayCartProcess()
    }
    return ready
}

import { Box } from "@mui/material";

interface Props {
  color?: string;
}

export default function ({ color }: Props) {
  return (
    <Box
      sx={{
        height: "36px",
        width: "36px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="36" height="36" rx="6" fill="#7E2AEA" />
        <path
          d="M16.5624 10.3047H9.80706C9.63748 10.3047 9.5 10.6421 9.5 11.0584V24.6249C9.5 25.0411 9.63748 25.3786 9.80706 25.3786H16.5624C16.732 25.3786 16.8695 25.0411 16.8695 24.6249V11.0584C16.8695 10.6421 16.732 10.3047 16.5624 10.3047Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="11.3918" cy="12.2004" r="0.786375" fill="white" />
        <path
          d="M26.1913 10.3047H19.436C19.2664 10.3047 19.1289 10.6421 19.1289 11.0584V24.6249C19.1289 25.0411 19.2664 25.3786 19.436 25.3786H26.1913C26.3609 25.3786 26.4984 25.0411 26.4984 24.6249V11.0584C26.4984 10.6421 26.3609 10.3047 26.1913 10.3047Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="21.0208" cy="12.2004" r="0.786375" fill="white" />
      </svg>
    </Box>
  );
}

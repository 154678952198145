import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";

interface Props {
  icon: any;
  text: string;
  isActive?: boolean;
  disabled?: boolean;
  isCollapsed: boolean;
  onClick: () => void;
}

export default function MenuItem({
  icon,
  text,
  isActive = false,
  isCollapsed,
  onClick,
  disabled = false,
}: Props) {
  const theme = useTheme();

  return (
    <ListItem
      sx={{
        px: 0,
        pt: "5px",
        pb: "3px",
      }}
    >
      <ListItemButton
        onClick={onClick}
        disabled={disabled}
        sx={{
          py: "4px",
          gap: "14px",
          backgroundColor: isActive ? theme.palette.darkPurple.main : undefined,
          justifyContent: "center",
        }}
      >
        <ListItemIcon sx={{ minWidth: 0 }}>{icon}</ListItemIcon>
        {!isCollapsed && (
          <ListItemText
            primary={text}
            primaryTypographyProps={{
              fontSize: "16px",
              lineHeight: "20px",
              color: "white",
            }}
          />
        )}
      </ListItemButton>
    </ListItem>
  );
}

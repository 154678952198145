import { cleanAuthTicketData } from "@root/ticket";
import { clearAuthToken } from "@frontend/kitui";
import { clearUserData } from "@root/user";
import { clearQuizData } from "@root/quizes/store";
import { logout } from "@api/auth";

export const handleLogoutClick = async () => {
  cleanAuthTicketData();
  clearAuthToken();
  clearUserData();
  clearQuizData();
  const [, logoutError] = await logout();
};

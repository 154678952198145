import { Box, Switch, useTheme } from "@mui/material";
import { TreeStructure } from "@icons/TreeStructure";

interface Props {
  openBranchingPage: boolean;
  setOpenBranchingPage: (a: boolean) => void;
}

export const SmallSwitchQuestionListGraph = ({
  openBranchingPage,
  setOpenBranchingPage,
}: Props) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "77px",
        height: "51px",
        position: "fixed",
        zIndex: "999",
        right: "0",
        top: "200px",
        background: "#333647",
        borderTopLeftRadius: "8px",
        borderBottomLeftRadius: "8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Switch
        icon={<TreeStructure />}
        checkedIcon={<TreeStructure />}
        checked={openBranchingPage}
        onChange={(e) => setOpenBranchingPage(e.target.checked)}
        sx={{
          width: 50,
          height: 30,
          padding: 0,
          "& .MuiSwitch-switchBase": {
            padding: 0,
            margin: "2px",
            width: "26px",
            height: "26px",
            background: "#fff",
            transitionDuration: "300ms",
            "&:hover": {
              backgroundColor: "#fff !important",
            },
            "&:active": {
              background: "#fff",
            },
            "&.Mui-checked": {
              transform: "translateX(20px)",
              color: theme.palette.brightPurple.main,
              "& + .MuiSwitch-track": {
                backgroundColor: "#7E2AEA",
                opacity: 1,
                border: 0,
              },
              "&.Mui-disabled + .MuiSwitch-track": { opacity: 0.5 },
            },
            "&.Mui-disabled .MuiSwitch-thumb": {
              color:
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[600],
            },
            "&.Mui-disabled + .MuiSwitch-track": {
              opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
            },
          },
          "& .MuiSwitch-thumb": {
            boxSizing: "border-box",
            width: 25,
            height: 25,
          },
          "& .MuiSwitch-track": {
            backgroundColor: "rgba(217, 188, 255, 0.16)",
            borderRadius: 13,
            opacity: 1,
            transition: theme.transitions.create(["background-color"], {
              duration: 500,
            }),
          },
        }}
      />
    </Box>
  );
};

import { QUIZ_QUESTION_BASE } from "./base";

import type { QuizQuestionRating } from "@frontend/squzanswerer";

export const QUIZ_QUESTION_RATING: Omit<QuizQuestionRating, "id" | "backendId"> = {
  ...QUIZ_QUESTION_BASE,
  type: "rating",
  content: {
    ...QUIZ_QUESTION_BASE.content,
    required: false,
    innerNameCheck: false,
    innerName: "",
    steps: 5,
    ratingExpanded: false,
    form: "star",
    ratingNegativeDescription: "",
    ratingPositiveDescription: "",
  },
};

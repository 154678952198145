import { Box } from "@mui/material";

interface Props {
  color?: string;
}

export default function ({ color }: Props) {
  return (
    <Box
      sx={{
        height: "36px",
        width: "36px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="36" height="36" rx="6" fill="#7E2AEA" />
        <path
          d="M9 23.1154V16.5385C9 15.7632 9.31607 15.0197 9.87868 14.4715C10.4413 13.9234 11.2044 13.6154 12 13.6154C12.7956 13.6154 13.5587 13.9234 14.1213 14.4715C14.6839 15.0197 15 15.7632 15 16.5385V23.1154M9 19.4615H15M24.75 18H21M24.75 18C25.3467 18 25.919 17.769 26.341 17.3579C26.7629 16.9468 27 16.3891 27 15.8077C27 15.2263 26.7629 14.6686 26.341 14.2575C25.919 13.8464 25.3467 13.6154 24.75 13.6154H21V18M24.75 18C25.3467 18 25.919 18.231 26.341 18.6421C26.7629 19.0532 27 19.6109 27 20.1923C27 20.7737 26.7629 21.3314 26.341 21.7425C25.919 22.1536 25.3467 22.3846 24.75 22.3846H21V18M18 8.5V27.5"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
}

import { makeRequest } from "@api/makeRequest";

import { replaceSpacesToEmptyLines } from "@utils/replaceSpacesToEmptyLines";
import { parseAxiosError } from "@utils/parse-error";

import type { CreateQuestionRequest } from "model/question/create";
import type { RawQuestion } from "model/question/question";
import type {
  GetQuestionListRequest,
  GetQuestionListResponse,
} from "@model/question/getList";
import type {
  EditQuestionRequest,
  EditQuestionResponse,
} from "@model/question/edit";
import type {
  DeleteQuestionRequest,
  DeleteQuestionResponse,
} from "@model/question/delete";
import type {
  CopyQuestionRequest,
  CopyQuestionResponse,
} from "@model/question/copy";

const API_URL = `${process.env.REACT_APP_DOMAIN}/squiz`;

export const createQuestion = async (
  body: CreateQuestionRequest,
): Promise<[RawQuestion | null, string?]> => {
  try {
    const createdQuestion = await makeRequest<
      CreateQuestionRequest,
      RawQuestion
    >({
      method: "POST",
      url: `${API_URL}/question/create`,
      body,
    });

    return [createdQuestion];
  } catch (nativeError) {
    const [error] = parseAxiosError(nativeError);

    return [null, `Не удалось создать вопрос. ${error}`];
  }
};

const getQuestionList = async (
  body?: Partial<GetQuestionListRequest>,
): Promise<[RawQuestion[] | null, string?]> => {
  try {
    if (!body?.quiz_id) return [null, "Квиз не найден"];

    const response = await makeRequest<
      GetQuestionListRequest,
      GetQuestionListResponse
    >({
      method: "POST",
      url: `${API_URL}/question/getList`,
      body: { ...defaultGetQuestionListBody, ...body },
    });

    const clearArrayFromEmptySpaceBlaBlaValue = response.items?.map(
      (question) => {
        let data = question;

        for (let key in question) {
          if (question[key as keyof RawQuestion] === " ") {
            //@ts-ignore
            data[key] = "";
          }
        }

        return data;
      },
    );

    return [
      replaceSpacesToEmptyLines(clearArrayFromEmptySpaceBlaBlaValue) ?? null,
    ];
  } catch (nativeError) {
    const [error] = parseAxiosError(nativeError);

    return [null, `Не удалось получить список вопросов. ${error}`];
  }
};

export const editQuestion = async (
  body: EditQuestionRequest,
  signal?: AbortSignal,
): Promise<[EditQuestionResponse | null, string?]> => {
  try {
    const editedQuestion = await makeRequest<
      EditQuestionRequest,
      EditQuestionResponse
    >({
      method: "PATCH",
      url: `${API_URL}/question/edit`,
      body,
      signal,
    });

    return [editedQuestion];
  } catch (nativeError) {
    const [error] = parseAxiosError(nativeError);

    return [null, `Не удалось изменить вопрос. ${error}`];
  }
};

export const copyQuestion = async (
  questionId: number,
  quizId: number,
): Promise<[CopyQuestionResponse | null, string?]> => {
  try {
    const copiedQuestion = await makeRequest<
      CopyQuestionRequest,
      CopyQuestionResponse
    >({
      method: "POST",
      url: `${API_URL}/question/copy`,
      body: { id: questionId, quiz_id: quizId },
    });

    return [copiedQuestion];
  } catch (nativeError) {
    const [error] = parseAxiosError(nativeError);

    return [null, `Не удалось скопировать вопрос. ${error}`];
  }
};

export const deleteQuestion = async (
  id: number,
): Promise<[DeleteQuestionResponse | null, string?]> => {
  try {
    const deletedQuestion = await makeRequest<
      DeleteQuestionRequest,
      DeleteQuestionResponse
    >({
      url: `${API_URL}/question/delete`,
      body: { id },
      method: "DELETE",
    });

    return [deletedQuestion];
  } catch (nativeError) {
    const [error] = parseAxiosError(nativeError);

    return [null, `Не удалось удалить вопрос. ${error}`];
  }
};

export const questionApi = {
  create: createQuestion,
  getList: getQuestionList,
  edit: editQuestion,
  copy: copyQuestion,
  delete: deleteQuestion,
};

const defaultGetQuestionListBody: GetQuestionListRequest = {
  limit: 100,
  offset: 0,
  type: "",
};

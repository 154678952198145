import { Box } from "@mui/material";

interface Props {
  height: string;
  width: string;
  color: string;
}

export default function PencilCircleIcon({ height, width, color }: Props) {
  return (
    <Box
      sx={{
        borderRadius: "6px",
        height,
        width,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="35"
        viewBox="0 0 35 35"
        fill="none"
      >
        <path
          d="M17.5 30.625C24.7487 30.625 30.625 24.7487 30.625 17.5C30.625 10.2513 24.7487 4.375 17.5 4.375C10.2513 4.375 4.375 10.2513 4.375 17.5C4.375 24.7487 10.2513 30.625 17.5 30.625Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.0625 28.875V24.0625L17.5 9.84375L10.9375 24.0625V28.875"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.5 26.25C17.5 25.3798 17.8457 24.5452 18.4611 23.9298C19.0764 23.3145 19.911 22.9688 20.7812 22.9688C21.6515 22.9688 22.4861 23.3145 23.1014 23.9298C23.7168 24.5452 24.0625 25.3798 24.0625 26.25"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.5 30.625V26.25C17.5 25.3798 17.1543 24.5452 16.5389 23.9298C15.9236 23.3145 15.089 22.9688 14.2188 22.9688C13.3485 22.9688 12.5139 23.3145 11.8986 23.9298C11.2832 24.5452 10.9375 25.3798 10.9375 26.25"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.9727 17.5H21.0273"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
}

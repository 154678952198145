import useSWR from "swr";
import { useQuizStore } from "./store";
import { quizApi } from "@api/quiz";
import { setQuizes } from "./actions";
import { isAxiosError } from "axios";
import { devlog } from "@frontend/kitui";
import { enqueueSnackbar } from "notistack";

export function useQuizes() {
  const { isLoading, error, isValidating } = useSWR(
    "quizes",
    () => quizApi.getList(),
    {
      onSuccess: ([quizes]) => setQuizes(quizes),
      onError: (error: unknown) => {
        const message = isAxiosError<string>(error)
          ? error.response?.data ?? ""
          : "";

        devlog("Error getting quiz list", error);
        enqueueSnackbar("Не удалось получить квизы");
      },
    },
  );
  const quizes = useQuizStore((state) => state.quizes);

  return { quizes, isLoading, error, isValidating };
}

export function useCurrentQuiz() {
  const { quizes, editQuizId } = useQuizStore();

  const quiz = quizes.find((q) => q.backendId === editQuizId);

  return quiz;
}

export function getCurrentQuiz() {
  const { quizes, editQuizId } = useQuizStore.getState();

  const quiz = quizes.find((q) => q.backendId === editQuizId);

  return quiz;
}

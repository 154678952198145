import { LogoutButton } from "@ui_kit/LogoutButton";
import BackArrowIcon from "@icons/BackArrowIcon";
import { Burger } from "@icons/Burger";

import {
  Box,
  Container,
  FormControl,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { updateQuiz } from "@root/quizes/actions";
import { useCurrentQuiz } from "@root/quizes/hooks";

import { Link, useNavigate } from "react-router-dom";

import Logotip from "../../pages/Landing/images/icons/QuizLogo";
import { ToTariffsButton } from "@ui_kit/Toolbars/ToTariffsButton";
import CustomTextField from "@ui_kit/CustomTextField";
import { handleLogoutClick } from "@utils/HandleLogoutClick";

type HeaderProps = {
  setMobileSidebar: (callback: (visible: boolean) => boolean) => void;
  scrollDown: boolean;
};

export const Header = ({ setMobileSidebar, scrollDown }: HeaderProps) => {
  const quiz = useCurrentQuiz();
  const theme = useTheme();
  const navigate = useNavigate();
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));
  const isMobile = useMediaQuery(theme.breakpoints.down(650));

  return (
    <Container
      component="nav"
      maxWidth={false}
      disableGutters
      sx={{
        px: "16px",
        display: "flex",
        height: isMobile ? "51px" : "80px",
        alignItems: "center",
        bgcolor: isMobile ? "#333647" : "white",
        borderBottom: "1px solid #E3E3E3",
        zIndex: theme.zIndex.drawer + 1,
        transition: "transform 0.3s",
        transform: scrollDown && isMobile ? "translateY(-51px)" : undefined,
        position: isMobile ? "fixed" : undefined,
        top: isMobile ? 0 : undefined,
      }}
    >
      <Link to="/" style={{ display: "flex" }}>
        {isMobile ? (
          <Logotip width={100} color={"white"} />
        ) : (
          <Logotip width={124} />
        )}
      </Link>
      <Box
        sx={{
          display: isMobile ? "none" : "flex",
          alignItems: "center",
          ml: "37px",
        }}
      >
        <Link to="/list">
          <IconButton sx={{ p: "6px" }}>
            <BackArrowIcon />
          </IconButton>
        </Link>
        <FormControl fullWidth variant="standard">
          <CustomTextField
            value={quiz.name}
            onChange={(e) =>
              updateQuiz(quiz.id, (quiz) => {
                quiz.name = e.target.value;
              })
            }
            maxLength={200}
            fullWidth
            id="project-name"
            placeholder="Название проекта"
            sx={{
              width: "270px",
              height: "34px",
              "& .MuiInputBase-root": {
                borderRadius: "8px",
                p: 0,
              },
            }}
            inputProps={{
              sx: {
                height: "20px",
                borderRadius: "8px",
                fontSize: "16px",
                lineHeight: "20px",
                p: "7px",
                color: "black",
                "&::placeholder": {
                  opacity: 1,
                },
              },
            }}
          />
        </FormControl>
      </Box>
      <Box
        sx={{
          display: "flex",
          ml: "auto",
          gap: "10px",
          alignItems: "center",
        }}
      >
        {isMobile ? (
          <>
            <ToTariffsButton />
            <Burger
              onClick={() => setMobileSidebar((visible: boolean) => !visible)}
              style={{ fontSize: "30px", color: "white", cursor: "pointer" }}
            />
          </>
        ) : (
          <>
            <ToTariffsButton />
            <LogoutButton
              onClick={() => {
                navigate("/");
                handleLogoutClick();
              }}
            />
          </>
        )}
      </Box>
    </Container>
  );
};

import { AnyTypedQuizQuestion } from "@frontend/squzanswerer";
import { useQuestionsStore } from "@root/questions/store";
import { updateSomeWorkBackend } from "@root/uiTools/actions";
import { DeleteFunction } from "@utils/deleteFunc";

export const deleteTimeoutedQuestions = async () => {
  const questions = useQuestionsStore.getState().questions;

  const questionsForDeletion = questions.filter(
    ({ type, deleted }) => type && type !== "result" && deleted
  ) as AnyTypedQuizQuestion[];
  if (questionsForDeletion.length > 0) {
    updateSomeWorkBackend(true);

    await Promise.allSettled(questionsForDeletion.map((question) => DeleteFunction(question.id)));

    updateSomeWorkBackend(false);
  }
};

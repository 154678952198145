import type { ChangeEvent, FocusEvent, KeyboardEvent } from "react";
import React, { useEffect, useState } from "react";
import type { InputProps, SxProps, Theme } from "@mui/material";
import {
  Box,
  FormControl,
  Input,
  InputLabel,
  Typography,
  useTheme,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";

interface CustomTextFieldProps {
  placeholder: string;
  id?: string;
  value?: string;
  error?: string;
  emptyError?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  text?: string;
  maxLength?: number;
  sx?: SxProps<Theme>;
  sxForm?: SxProps<Theme>;
  InputProps?: Partial<InputProps>;
  type?: string;
  rows?: number;
  className?: string;
  disabled?: boolean;
}

export default function CustomTextField({
  placeholder,
  id,
  value = "",
  onChange,
  onKeyDown,
  onBlur,
  text,
  sx,
  error,
  emptyError,
  InputProps,
  maxLength = 200,
  type = "",
  rows = 0,
  sxForm,
  className,
  disabled,
}: CustomTextFieldProps) {
  const theme = useTheme();

  const [inputValue, setInputValue] = useState("");
  const [isInputActive, setIsInputActive] = useState(false);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length <= maxLength) {
      const inputValue = event.target.value;

      if (type === "number") {
        setInputValue(inputValue.replace(/\D/g, ""));
      } else {
        setInputValue(inputValue);
      }

      if (onChange) {
        onChange(event);
      }
    } else {
      enqueueSnackbar("Превышена длина вводимого текста")
    }
  };

  const handleInputFocus = () => {
    setIsInputActive(true);
  };

  const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setIsInputActive(false);

    if (onBlur) {
      onBlur(event);
    }
  };

  return (
    <FormControl
      fullWidth
      variant="standard"
      sx={{ p: 0, ...sxForm }}
      className={className || ""}
    >
      {error && (
        <InputLabel
          sx={{
            fontSize: "13.5px",
            marginTop: "3px",
          }}
        >
          {error}
        </InputLabel>
      )}
      <Input
        id={id}
        defaultValue={text}
        fullWidth
        value={inputValue}
        placeholder={placeholder}
        onChange={handleInputChange}
        error={!!error || emptyError}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        onKeyDown={onKeyDown}
        multiline={rows > 0}
        rows={rows}
        disabled={disabled}
        disableUnderline
        sx={{
          maxLength: maxLength,
          borderRadius: "10px",
          fontSize: "18px",
          lineHeight: "21px",
          p: "13px",
          border: `${isInputActive ? "black 2px" : "#9A9AAF 1px"} solid`,
          backgroundColor: theme.palette.background.default,
          height: "48px",
          ...sx,
        }}
        data-cy="textfield"
      />
      {isInputActive && inputValue.length >= maxLength - 7 && (
        <Box
          sx={{
            display: "flex",
            marginTop: "5px",
            marginLeft: "auto",
            position: "absolute",
            bottom: "-25px",
            right: "0",
          }}
        >
          <Typography fontSize="14px">{inputValue.length}</Typography>
          <span>/</span>
          <Typography fontSize="14px">{maxLength}</Typography>
        </Box>
      )}
    </FormControl>
  );
}

import { IconButton, IconButtonProps } from "@mui/material";
import { deepmerge } from "@mui/utils";

export function LogoutButton(props: IconButtonProps) {
  return (
    <IconButton
      {...deepmerge(
        {
          sx: {
            height: 36,
            width: 36,
            p: 0,
            borderRadius: "6px",
            backgroundColor: "#F2F3F7",
            color: "#9A9AAF",
            "&:hover": {
              color: "#F2F3F7",
              backgroundColor: "#9A9AAF",
            },
            "&:active": {
              backgroundColor: "#7E2AEA",
              color: "white",
            },
          },
        },
        props,
      )}
    >
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 36 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.559 22.052v2.95a2 2 0 0 1-2 2h-6.865a2 2 0 0 1-2-2v-12.5a2 2 0 0 1 2-2h6.865a2 2 0 0 1 2 2v2.95M25.067 21.227l1.786-1.763a1 1 0 0 0 0-1.423l-1.786-1.764M26.737 18.752H16.71"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    </IconButton>
  );
}

import { Box } from "@mui/material";

interface Props {
  height: string;
  width: string;
  color: string;
}

export default function FlowArrowIcon({ height, width, color }: Props) {
  return (
    <Box
      sx={{
        borderRadius: "6px",
        height,
        width,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="35"
        viewBox="0 0 35 35"
        fill="none"
      >
        <path
          d="M6.01562 27.8906C8.12984 27.8906 9.84375 26.1767 9.84375 24.0625C9.84375 21.9483 8.12984 20.2344 6.01562 20.2344C3.90141 20.2344 2.1875 21.9483 2.1875 24.0625C2.1875 26.1767 3.90141 27.8906 6.01562 27.8906Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.3438 5.46875L32.8125 10.9375L27.3438 16.4062"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.84375 24.0625H10.9375C12.678 24.0625 14.3472 23.3711 15.5779 22.1404C16.8086 20.9097 17.5 19.2405 17.5 17.5C17.5 15.7595 18.1914 14.0903 19.4221 12.8596C20.6528 11.6289 22.322 10.9375 24.0625 10.9375H32.8125"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
}

import { QUIZ_QUESTION_BASE } from "./base";

import type { QuizQuestionText } from "@frontend/squzanswerer";

export const QUIZ_QUESTION_TEXT: Omit<QuizQuestionText, "id" | "backendId"> = {
  ...QUIZ_QUESTION_BASE,
  type: "text",
  content: {
    ...QUIZ_QUESTION_BASE.content,
    placeholder: "",
    innerNameCheck: false,
    innerName: "",
    required: false,
    answerType: "single",
    onlyNumbers: false,
  },
};

import { FC, SVGProps } from "react";

export const Burger: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    {...props}
    height="1em"
    viewBox="0 0 30 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 8.15039L3 8.15039"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M28 16.1504H3"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M28 24.1504H3"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

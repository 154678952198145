import React from "react";
import { CssBaseline, useMediaQuery, useTheme } from "@mui/material";
import Header from "./HeaderLanding";
import Footer from "./FooterLanding";
import Hero from "./Hero";
import Counter from "./Counter";
import HowItWorks from "./HowItWorks";
import BusinessPluses from "./BusinessPluses";
import HowToUse from "./HowToUse";
import WhatTheFeatures from "./WhatTheFeatures";
import { clearAuthToken, setAuthToken } from "@frontend/kitui";
import { useParams } from "react-router-dom";
import { clearUserData, setUserId, useUserStore } from "@root/user";
import { cleanAuthTicketData } from "@root/ticket";
import { Tariffs } from "@/pages/Landing/Tariffs/Tariffs";

export default function Landing() {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));
  const data = useParams();
  const { user } = useUserStore();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const userId = urlParams.get("userId");
  const action = urlParams.get("action");

  if (userId && action) {
    history.pushState(null, document.title, "/");
    if (user?._id) {
      cleanAuthTicketData();
      clearAuthToken();
      clearUserData();
    }
    setAuthToken(action);
    setUserId(userId);
  }

  return (
    <>
      <CssBaseline />
      <Header />
      <Hero />
      <Counter />
      <Tariffs />
      {/* <Collaboration/> */}
      <HowItWorks />
      <BusinessPluses />
      <HowToUse />
      {/*<WhatTheySay/>*/}
      {/*<StartWithTemplates/>*/}
      <WhatTheFeatures />
      {/*<Blog/>*/}
      {/*<Questions/>*/}
      <Footer />
    </>
  );
}

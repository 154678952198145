import {
  Box,
  Container,
  IconButton,
  SxProps,
  Theme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Logotip from "../../pages/Landing/images/icons/QuizLogo";
import { Link, useNavigate } from "react-router-dom";
import { LogoutButton } from "@ui_kit/LogoutButton";
import { ToTariffsButton } from "@ui_kit/Toolbars/ToTariffsButton";
import ArrowLeft from "@icons/questionsPage/arrowLeft";
import { handleLogoutClick } from "@utils/HandleLogoutClick";

interface HeaderFullProps {
  isRequest: boolean;
  sx?: SxProps<Theme>;
}

export default function HeaderFull({ isRequest = false, sx }: HeaderFullProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));
  const isMobile = useMediaQuery(theme.breakpoints.down(500));

  return (
    <Container
      component="nav"
      disableGutters
      maxWidth={false}
      sx={{
        px: "16px",
        display: "flex",
        height: "80px",
        alignItems: "center",
        gap: isTablet ? "20px" : "60px",
        flexDirection: "row",
        justifyContent: isMobile ? "space-between" : "center",
        bgcolor: "white",
        borderBottom: "1px solid #E3E3E3",
        ...sx,
      }}
    >
      <Link to="/">
        <Logotip width={124} />
      </Link>
      {isRequest && (
        <IconButton onClick={() => navigate("/list")}>
          <ArrowLeft color="black" />
        </IconButton>
      )}
      <Box sx={{ display: "flex", ml: "auto" }}>
        <ToTariffsButton />
        <LogoutButton
          onClick={() => {
            navigate("/");
            handleLogoutClick();
          }}
          sx={{
            ml: "20px",
          }}
        />
      </Box>
    </Container>
  );
}

import { Box } from "@mui/material";

interface Props {
  height: string;
  width: string;
  color: string;
}

export default function QuestionIcon({ height, width, color }: Props) {
  return (
    <Box
      sx={{
        borderRadius: "6px",
        height,
        width,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="35"
        viewBox="0 0 35 35"
        fill="none"
      >
        <path
          d="M7.45117 27.5488C6.19336 26.291 7.02734 23.6523 6.38477 22.1074C5.74219 20.5625 3.28125 19.209 3.28125 17.5C3.28125 15.791 5.71484 14.4922 6.38477 12.8926C7.05469 11.293 6.19336 8.70898 7.45117 7.45117C8.70898 6.19336 11.3477 7.02734 12.8926 6.38477C14.4375 5.74219 15.791 3.28125 17.5 3.28125C19.209 3.28125 20.5078 5.71484 22.1074 6.38477C23.707 7.05469 26.291 6.19336 27.5488 7.45117C28.8066 8.70898 27.9727 11.3477 28.6152 12.8926C29.2578 14.4375 31.7188 15.791 31.7188 17.5C31.7188 19.209 29.2852 20.5078 28.6152 22.1074C27.9453 23.707 28.8066 26.291 27.5488 27.5488C26.291 28.8066 23.6523 27.9727 22.1074 28.6152C20.5625 29.2578 19.209 31.7188 17.5 31.7188C15.791 31.7188 14.4922 29.2852 12.8926 28.6152C11.293 27.9453 8.70898 28.8066 7.45117 27.5488Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.5 26.25C18.4061 26.25 19.1406 25.5155 19.1406 24.6094C19.1406 23.7033 18.4061 22.9688 17.5 22.9688C16.5939 22.9688 15.8594 23.7033 15.8594 24.6094C15.8594 25.5155 16.5939 26.25 17.5 26.25Z"
          fill={color}
        />
        <path
          d="M17.5 19.6875V18.5938C18.2571 18.5938 18.9973 18.3692 19.6268 17.9486C20.2563 17.528 20.747 16.9301 21.0367 16.2306C21.3265 15.5311 21.4023 14.7614 21.2546 14.0188C21.1069 13.2762 20.7423 12.5941 20.2069 12.0587C19.6715 11.5234 18.9894 11.1588 18.2468 11.0111C17.5042 10.8633 16.7345 10.9392 16.035 11.2289C15.3355 11.5186 14.7377 12.0093 14.317 12.6388C13.8964 13.2684 13.6719 14.0085 13.6719 14.7656"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
}

import { Typography, Box } from "@mui/material";
import { useUserStore } from "@root/user";
import { Link } from "react-router-dom";
export const InfoPrivilege = () => {
  const user = useUserStore();
  return (
    <Box>
      <Link to="/list">К списку квизов</Link>
      {Object.values(user?.userAccount?.privileges || {}).map((privilege) => {
        return (
          <Box
            sx={{
              border: "1px solid",
              margin: "0 10px",
              display: "flex",
              justifyContent: "space-around",
              padding: "5px 0",
            }}
          >
            <Typography>{privilege?.privilege_name}</Typography>
            <Typography>{privilege?.amount}</Typography>
          </Box>
        );
      })}
    </Box>
  );
};

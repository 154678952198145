import { Box, SxProps } from "@mui/material";

interface Props {
  sx?: SxProps;
}

export default function ({ sx }: Props) {
  return (
    <Box
      sx={sx}
    >
      <svg viewBox="0 0 334 112" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="333" height="111" rx="17.5" fill="#196CFC" />
        <rect x="0.5" y="0.5" width="333" height="111" rx="17.5" stroke="#0058FC" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M81.2001 76.8H71.6001C53.9241 76.8 39.6001 62.476 39.6001 44.8V35.2H62.0001V24H28.4001V44.8C28.4001 68.664 47.7441 88 71.6001 88H92.4001V52.8H81.2001V76.8ZM68.4001 24V35.2H81.2001V46.4H92.4001V24H68.4001Z" fill="white" />
        <path d="M288.743 92.3636V65.3636H294.112V68.6619H294.355C294.594 68.1335 294.939 67.5966 295.391 67.0511C295.851 66.4972 296.448 66.0369 297.181 65.6705C297.922 65.2955 298.843 65.108 299.942 65.108C301.374 65.108 302.695 65.483 303.905 66.233C305.115 66.9744 306.083 68.0952 306.807 69.5952C307.532 71.0867 307.894 72.9574 307.894 75.2074C307.894 77.3977 307.54 79.2472 306.833 80.7557C306.134 82.2557 305.179 83.3935 303.969 84.169C302.767 84.9361 301.421 85.3196 299.929 85.3196C298.872 85.3196 297.973 85.1449 297.232 84.7955C296.499 84.446 295.898 84.0071 295.429 83.4787C294.96 82.9418 294.603 82.4006 294.355 81.8551H294.189V92.3636H288.743ZM294.074 75.1818C294.074 76.3494 294.236 77.3679 294.56 78.2372C294.884 79.1065 295.353 79.7841 295.966 80.2699C296.58 80.7472 297.326 80.9858 298.203 80.9858C299.09 80.9858 299.84 80.7429 300.453 80.2571C301.067 79.7628 301.532 79.081 301.847 78.2117C302.171 77.3338 302.333 76.3239 302.333 75.1818C302.333 74.0483 302.175 73.0511 301.86 72.1903C301.544 71.3296 301.08 70.6563 300.466 70.1705C299.853 69.6847 299.098 69.4418 298.203 69.4418C297.317 69.4418 296.567 69.6761 295.953 70.1449C295.348 70.6136 294.884 71.2784 294.56 72.1392C294.236 73 294.074 74.0142 294.074 75.1818Z" fill="white" />
        <path d="M268.294 69.6463V65.3636H286.09V69.6463H279.762V85H274.571V69.6463H268.294Z" fill="white" />
        <path d="M263.276 73.0085V77.2912H253.023V73.0085H263.276ZM254.813 65.3636V85H249.609V65.3636H254.813ZM266.689 65.3636V85H261.524V65.3636H266.689Z" fill="white" />
        <path d="M237.691 85.3835C235.671 85.3835 233.932 84.9744 232.475 84.1563C231.026 83.3296 229.91 82.1619 229.125 80.6534C228.341 79.1364 227.949 77.3423 227.949 75.2713C227.949 73.2514 228.341 71.4787 229.125 69.9531C229.91 68.4276 231.013 67.2386 232.436 66.3864C233.868 65.5341 235.547 65.108 237.473 65.108C238.769 65.108 239.975 65.3168 241.091 65.7344C242.216 66.1435 243.196 66.7614 244.032 67.5881C244.875 68.4148 245.532 69.4546 246 70.7074C246.469 71.9517 246.704 73.4091 246.704 75.0796V76.5753H230.123V73.2003H241.577C241.577 72.4162 241.407 71.7216 241.066 71.1165C240.725 70.5114 240.252 70.0384 239.647 69.6974C239.05 69.348 238.356 69.1733 237.563 69.1733C236.736 69.1733 236.003 69.3651 235.364 69.7486C234.733 70.1236 234.239 70.6307 233.881 71.2699C233.523 71.9006 233.34 72.6037 233.331 73.3793V76.5881C233.331 77.5597 233.51 78.3992 233.868 79.1065C234.235 79.8139 234.75 80.3594 235.415 80.7429C236.08 81.1264 236.868 81.3182 237.78 81.3182C238.385 81.3182 238.939 81.233 239.442 81.0625C239.945 80.8921 240.375 80.6364 240.733 80.2955C241.091 79.9546 241.364 79.5369 241.552 79.0426L246.588 79.375C246.333 80.5852 245.809 81.6421 245.016 82.5455C244.232 83.4403 243.218 84.1392 241.973 84.6421C240.738 85.1364 239.31 85.3835 237.691 85.3835Z" fill="white" />
        <path d="M226.133 80.5767L225.814 91.1875H220.547V85H218.706V80.5767H226.133ZM200.91 85V58.8182H206.228V80.5511H217.12V58.8182H222.502V85H200.91Z" fill="white" />
        <path d="M188.889 85.3835C186.869 85.3835 185.131 84.9744 183.673 84.1563C182.224 83.3296 181.108 82.1619 180.324 80.6534C179.54 79.1364 179.148 77.3423 179.148 75.2713C179.148 73.2514 179.54 71.4787 180.324 69.9531C181.108 68.4276 182.212 67.2386 183.635 66.3864C185.067 65.5341 186.746 65.108 188.672 65.108C189.967 65.108 191.173 65.3168 192.29 65.7344C193.415 66.1435 194.395 66.7614 195.23 67.5881C196.074 68.4148 196.73 69.4546 197.199 70.7074C197.668 71.9517 197.902 73.4091 197.902 75.0796V76.5753H181.321V73.2003H192.775C192.775 72.4162 192.605 71.7216 192.264 71.1165C191.923 70.5114 191.45 70.0384 190.845 69.6974C190.248 69.348 189.554 69.1733 188.761 69.1733C187.935 69.1733 187.202 69.3651 186.562 69.7486C185.932 70.1236 185.437 70.6307 185.079 71.2699C184.722 71.9006 184.538 72.6037 184.53 73.3793V76.5881C184.53 77.5597 184.709 78.3992 185.067 79.1065C185.433 79.8139 185.949 80.3594 186.614 80.7429C187.278 81.1264 188.067 81.3182 188.979 81.3182C189.584 81.3182 190.138 81.233 190.641 81.0625C191.143 80.8921 191.574 80.6364 191.932 80.2955C192.29 79.9546 192.562 79.5369 192.75 79.0426L197.787 79.375C197.531 80.5852 197.007 81.6421 196.214 82.5455C195.43 83.4403 194.416 84.1392 193.172 84.6421C191.936 85.1364 190.508 85.3835 188.889 85.3835Z" fill="white" />
        <path d="M166.654 92.7727C164.889 92.7727 163.377 92.5298 162.115 92.044C160.862 91.5668 159.865 90.9148 159.124 90.0881C158.382 89.2614 157.901 88.3324 157.679 87.3011L162.716 86.6236C162.87 87.0156 163.112 87.3821 163.445 87.723C163.777 88.0639 164.216 88.3367 164.762 88.5412C165.316 88.7543 165.989 88.8608 166.781 88.8608C167.966 88.8608 168.942 88.571 169.709 87.9915C170.485 87.4205 170.872 86.4617 170.872 85.1151V81.5227H170.642C170.404 82.0682 170.046 82.5838 169.568 83.0696C169.091 83.5554 168.477 83.9517 167.727 84.2585C166.977 84.5653 166.083 84.7188 165.043 84.7188C163.568 84.7188 162.226 84.3778 161.016 83.696C159.814 83.0057 158.855 81.9531 158.139 80.5384C157.432 79.1151 157.078 77.3168 157.078 75.1435C157.078 72.919 157.441 71.0611 158.165 69.5696C158.889 68.0781 159.852 66.9617 161.054 66.2202C162.264 65.4787 163.59 65.108 165.03 65.108C166.129 65.108 167.05 65.2955 167.791 65.6705C168.533 66.0369 169.129 66.4972 169.581 67.0511C170.041 67.5966 170.395 68.1335 170.642 68.6619H170.847V65.3636H176.254V85.1918C176.254 86.8622 175.845 88.2599 175.027 89.3849C174.209 90.5099 173.075 91.3537 171.627 91.9162C170.186 92.4872 168.529 92.7727 166.654 92.7727ZM166.769 80.6278C167.646 80.6278 168.388 80.4105 168.993 79.9759C169.607 79.5327 170.075 78.902 170.399 78.0838C170.732 77.2571 170.898 76.2685 170.898 75.1179C170.898 73.9673 170.736 72.9702 170.412 72.1264C170.088 71.2742 169.619 70.6136 169.006 70.1449C168.392 69.6761 167.646 69.4418 166.769 69.4418C165.874 69.4418 165.119 69.6847 164.506 70.1705C163.892 70.6477 163.428 71.3125 163.112 72.1648C162.797 73.0171 162.639 74.0014 162.639 75.1179C162.639 76.2514 162.797 77.2315 163.112 78.0582C163.436 78.8764 163.901 79.5114 164.506 79.9631C165.119 80.4063 165.874 80.6278 166.769 80.6278Z" fill="white" />
        <path d="M142.804 85.3196C141.313 85.3196 139.962 84.9361 138.752 84.169C137.55 83.3935 136.595 82.2557 135.888 80.7557C135.189 79.2472 134.84 77.3977 134.84 75.2074C134.84 72.9574 135.202 71.0866 135.926 69.5952C136.651 68.0952 137.614 66.9744 138.815 66.233C140.026 65.483 141.351 65.108 142.791 65.108C143.891 65.108 144.807 65.2955 145.54 65.6705C146.281 66.0369 146.878 66.4972 147.33 67.0511C147.79 67.5966 148.139 68.1335 148.378 68.6619H148.544V58.8182H153.977V85H148.608V81.8551H148.378C148.122 82.4006 147.76 82.9418 147.291 83.4787C146.831 84.0071 146.23 84.446 145.489 84.7955C144.756 85.1449 143.861 85.3196 142.804 85.3196ZM144.53 80.9858C145.408 80.9858 146.149 80.7472 146.754 80.2699C147.368 79.7841 147.837 79.1065 148.161 78.2372C148.493 77.3679 148.659 76.3494 148.659 75.1818C148.659 74.0142 148.497 73 148.173 72.1392C147.85 71.2784 147.381 70.6136 146.767 70.1449C146.154 69.6761 145.408 69.4418 144.53 69.4418C143.635 69.4418 142.881 69.6847 142.267 70.1705C141.654 70.6563 141.189 71.3296 140.874 72.1903C140.558 73.0511 140.401 74.0483 140.401 75.1818C140.401 76.3239 140.558 77.3338 140.874 78.2117C141.198 79.081 141.662 79.7628 142.267 80.2571C142.881 80.7429 143.635 80.9858 144.53 80.9858Z" fill="white" />
        <path d="M114.276 85V58.8182H131.918V63.3821H119.811V69.6207H131.01V74.1847H119.811V80.4361H131.969V85H114.276Z" fill="white" />
        <path d="M199.628 49.9091C199.287 49.9091 198.983 49.8807 198.716 49.8239C198.449 49.7727 198.264 49.7216 198.162 49.6705L198.673 47.8977C199.162 48.0227 199.594 48.0682 199.969 48.0341C200.344 48 200.676 47.8324 200.966 47.5313C201.262 47.2358 201.531 46.7557 201.776 46.0909L202.151 45.0682L197.31 31.9091H199.492L203.105 42.3409H203.242L206.855 31.9091H209.037L203.48 46.9091C203.23 47.5852 202.921 48.1449 202.551 48.5881C202.182 49.0369 201.753 49.3693 201.264 49.5852C200.781 49.8012 200.236 49.9091 199.628 49.9091Z" fill="white" />
        <path d="M184.6 45V27.5455H186.611V33.9886H186.782C186.929 33.7614 187.134 33.4716 187.395 33.1193C187.662 32.7614 188.043 32.4432 188.537 32.1648C189.037 31.8807 189.713 31.7386 190.566 31.7386C191.668 31.7386 192.64 32.0142 193.48 32.5653C194.321 33.1165 194.978 33.8977 195.449 34.9091C195.921 35.9205 196.157 37.1136 196.157 38.4886C196.157 39.875 195.921 41.0767 195.449 42.0938C194.978 43.1051 194.324 43.8892 193.489 44.446C192.654 44.9972 191.691 45.2727 190.6 45.2727C189.759 45.2727 189.086 45.1335 188.58 44.8551C188.074 44.571 187.685 44.25 187.412 43.892C187.14 43.5284 186.929 43.2273 186.782 42.9886H186.543V45H184.6ZM186.577 38.4545C186.577 39.4432 186.722 40.3153 187.012 41.071C187.301 41.821 187.725 42.4091 188.282 42.8352C188.838 43.2557 189.52 43.4659 190.327 43.4659C191.168 43.4659 191.87 43.2443 192.432 42.8011C193 42.3523 193.426 41.75 193.711 40.9943C194 40.233 194.145 39.3864 194.145 38.4545C194.145 37.5341 194.003 36.7045 193.719 35.9659C193.441 35.2216 193.017 34.6335 192.449 34.2017C191.887 33.7642 191.179 33.5455 190.327 33.5455C189.509 33.5455 188.821 33.7528 188.265 34.1676C187.708 34.5767 187.287 35.1506 187.003 35.8892C186.719 36.6222 186.577 37.4773 186.577 38.4545Z" fill="white" />
        <path d="M168.478 45.2727C167.387 45.2727 166.424 44.9972 165.589 44.446C164.754 43.8892 164.1 43.1051 163.629 42.0938C163.157 41.0767 162.921 39.875 162.921 38.4886C162.921 37.1136 163.157 35.9205 163.629 34.9091C164.1 33.8977 164.756 33.1165 165.597 32.5653C166.438 32.0142 167.41 31.7386 168.512 31.7386C169.364 31.7386 170.038 31.8807 170.532 32.1648C171.032 32.4432 171.413 32.7614 171.674 33.1193C171.941 33.4716 172.148 33.7614 172.296 33.9886H172.467V27.5455H174.478V45H172.535V42.9886H172.296C172.148 43.2273 171.938 43.5284 171.665 43.892C171.393 44.25 171.004 44.571 170.498 44.8551C169.992 45.1335 169.319 45.2727 168.478 45.2727ZM168.751 43.4659C169.558 43.4659 170.239 43.2557 170.796 42.8352C171.353 42.4091 171.776 41.821 172.066 41.071C172.356 40.3153 172.501 39.4432 172.501 38.4545C172.501 37.4773 172.359 36.6222 172.075 35.8892C171.79 35.1506 171.37 34.5767 170.813 34.1676C170.256 33.7528 169.569 33.5455 168.751 33.5455C167.898 33.5455 167.188 33.7642 166.62 34.2017C166.058 34.6335 165.634 35.2216 165.35 35.9659C165.072 36.7045 164.933 37.5341 164.933 38.4545C164.933 39.3864 165.075 40.233 165.359 40.9943C165.648 41.75 166.075 42.3523 166.637 42.8011C167.205 43.2443 167.91 43.4659 168.751 43.4659Z" fill="white" />
        <path d="M158.271 39.6477V31.9091H160.283V45H158.271V42.7841H158.135C157.828 43.4489 157.351 44.0142 156.703 44.4801C156.056 44.9404 155.237 45.1705 154.249 45.1705C153.431 45.1705 152.703 44.9915 152.067 44.6335C151.431 44.2699 150.931 43.7244 150.567 42.9972C150.203 42.2642 150.021 41.3409 150.021 40.2273V31.9091H152.033V40.0909C152.033 41.0455 152.3 41.8068 152.834 42.375C153.374 42.9432 154.061 43.2273 154.896 43.2273C155.396 43.2273 155.905 43.0994 156.422 42.8438C156.945 42.5881 157.382 42.196 157.735 41.6676C158.093 41.1392 158.271 40.4659 158.271 39.6477Z" fill="white" />
        <path d="M141.451 45.2727C140.27 45.2727 139.233 44.9915 138.341 44.429C137.454 43.8665 136.761 43.0796 136.261 42.0682C135.767 41.0568 135.52 39.875 135.52 38.5227C135.52 37.1591 135.767 35.9688 136.261 34.9517C136.761 33.9347 137.454 33.1449 138.341 32.5824C139.233 32.0199 140.27 31.7386 141.451 31.7386C142.633 31.7386 143.667 32.0199 144.554 32.5824C145.446 33.1449 146.139 33.9347 146.633 34.9517C147.133 35.9688 147.383 37.1591 147.383 38.5227C147.383 39.875 147.133 41.0568 146.633 42.0682C146.139 43.0796 145.446 43.8665 144.554 44.429C143.667 44.9915 142.633 45.2727 141.451 45.2727ZM141.451 43.4659C142.349 43.4659 143.088 43.2358 143.667 42.7756C144.247 42.3154 144.676 41.7102 144.954 40.9602C145.233 40.2102 145.372 39.3977 145.372 38.5227C145.372 37.6477 145.233 36.8324 144.954 36.0767C144.676 35.321 144.247 34.7102 143.667 34.2443C143.088 33.7784 142.349 33.5455 141.451 33.5455C140.554 33.5455 139.815 33.7784 139.235 34.2443C138.656 34.7102 138.227 35.321 137.949 36.0767C137.67 36.8324 137.531 37.6477 137.531 38.5227C137.531 39.3977 137.67 40.2102 137.949 40.9602C138.227 41.7102 138.656 42.3154 139.235 42.7756C139.815 43.2358 140.554 43.4659 141.451 43.4659Z" fill="white" />
        <path d="M132.881 27.5455V45H130.87V27.5455H132.881Z" fill="white" />
        <path d="M128.159 33H126.045C125.92 32.3921 125.702 31.858 125.389 31.3977C125.082 30.9375 124.707 30.5511 124.264 30.2386C123.827 29.9205 123.341 29.6818 122.807 29.5227C122.273 29.3636 121.716 29.2841 121.136 29.2841C120.08 29.2841 119.122 29.5511 118.264 30.0852C117.412 30.6193 116.733 31.4063 116.227 32.446C115.727 33.4858 115.477 34.7614 115.477 36.2727C115.477 37.7841 115.727 39.0597 116.227 40.0994C116.733 41.1392 117.412 41.9261 118.264 42.4602C119.122 42.9943 120.08 43.2614 121.136 43.2614C121.716 43.2614 122.273 43.1818 122.807 43.0227C123.341 42.8636 123.827 42.6278 124.264 42.3153C124.707 41.9972 125.082 41.608 125.389 41.1477C125.702 40.6818 125.92 40.1477 126.045 39.5455H128.159C128 40.4375 127.71 41.2358 127.29 41.9403C126.869 42.6449 126.347 43.2443 125.722 43.7386C125.097 44.2273 124.395 44.5994 123.616 44.8551C122.844 45.1108 122.017 45.2386 121.136 45.2386C119.648 45.2386 118.324 44.875 117.165 44.1477C116.006 43.4205 115.094 42.3864 114.429 41.0455C113.764 39.7046 113.432 38.1136 113.432 36.2727C113.432 34.4318 113.764 32.8409 114.429 31.5C115.094 30.1591 116.006 29.125 117.165 28.3977C118.324 27.6705 119.648 27.3068 121.136 27.3068C122.017 27.3068 122.844 27.4347 123.616 27.6903C124.395 27.946 125.097 28.321 125.722 28.8153C126.347 29.304 126.869 29.9006 127.29 30.6051C127.71 31.304 128 32.1023 128.159 33Z" fill="white" />
      </svg>
    </Box>
  );
}

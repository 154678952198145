export class RequestQueue<IdType, T = unknown> {
  private pendingPromise = false;
  private items: Array<{
    id: IdType;
    action: () => Promise<T>;
    resolve: (value: T) => void;
    reject: (reason?: any) => void;
  }> = [];

  enqueue(id: IdType, action: () => Promise<T>) {
    return new Promise((resolve, reject) => {
      this.items.push({ action, resolve, reject, id });
      this.dequeue();
    });
  }

  async dequeue() {
    if (this.pendingPromise) return;

    const item = this.items.shift();
    if (!item) return;

    this.items = this.items.filter((i) => i.id !== item.id);

    try {
      this.pendingPromise = true;
      const payload = await item.action();
      this.pendingPromise = false;

      item.resolve(payload);
    } catch (e) {
      this.pendingPromise = false;
      item.reject(e);
    } finally {
      this.dequeue();
    }
  }
}

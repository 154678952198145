import { useEffect, useState } from "react";

export function useDomainDefine(): { isTestServer: boolean } {
  const [isTestServer, setIsTestServer] = useState<boolean>(false);

  useEffect(() => {
    const host = window.location.hostname;
    let isTest = host.includes("s");
    setIsTestServer(isTest);
  }, []);

  return { isTestServer };
}

import { create } from "zustand";
import { devtools } from "zustand/middleware";

interface QuizPreviewStore {
  isPreviewShown: boolean;
}

export const useQuizPreviewStore = create<QuizPreviewStore>()(
  devtools(
    (set, get) => ({
      isPreviewShown: false,
    }),
    {
      name: "quizPreview",
      enabled: process.env.NODE_ENV !== "production",
    },
  ),
);

export const showQuizPreview = () =>
  useQuizPreviewStore.setState({ isPreviewShown: true });

export const hideQuizPreview = () =>
  useQuizPreviewStore.setState({ isPreviewShown: false });

export const toggleQuizPreview = () =>
  useQuizPreviewStore.setState((state) => ({
    isPreviewShown: !state.isPreviewShown,
  }));

import { Box } from "@mui/material";

interface Props {
  height: string;
  width: string;
  color: string;
}

export default function ContactBookIcon({ height, width, color }: Props) {
  return (
    <Box
      sx={{
        borderRadius: "6px",
        height,
        width,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="35"
        viewBox="0 0 35 35"
        fill="none"
      >
        <path
          d="M18.5938 19.6875C21.01 19.6875 22.9688 17.7287 22.9688 15.3125C22.9688 12.8963 21.01 10.9375 18.5938 10.9375C16.1775 10.9375 14.2188 12.8963 14.2188 15.3125C14.2188 17.7287 16.1775 19.6875 18.5938 19.6875Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.375 14.7656H7.65625"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.375 9.29688H7.65625"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.375 20.2344H7.65625"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.375 25.7031H7.65625"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.0312 22.9688C12.7953 21.95 13.7862 21.1231 14.9252 20.5535C16.0642 19.984 17.3203 19.6875 18.5938 19.6875C19.8672 19.6875 21.1232 19.984 22.2623 20.5535C23.4013 21.1231 24.3922 21.95 25.1562 22.9688"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.5312 29.5312V5.46875C29.5312 4.86469 29.0416 4.375 28.4375 4.375L8.75 4.375C8.14594 4.375 7.65625 4.86469 7.65625 5.46875V29.5312C7.65625 30.1353 8.14594 30.625 8.75 30.625H28.4375C29.0416 30.625 29.5312 30.1353 29.5312 29.5312Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
}

import { Box } from "@mui/material";

interface Props {
  color?: string;
}

export default function ({ color }: Props) {
  return (
    <Box
      sx={{
        height: "36px",
        width: "36px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="36" height="36" rx="6" fill="#7E2AEA" />
        <path
          d="M14.1429 16.7143L10.0651 20.792C9.38314 21.474 9 22.399 9 23.3635C9 25.3719 10.6281 27 12.6365 27C13.601 27 14.526 26.6169 15.208 25.9349L19.2857 21.8571M21.8571 19.2857L25.9349 15.208C26.6169 14.526 27 13.601 27 12.6365C27 10.6281 25.3719 9 23.3635 9C22.399 9 21.474 9.38313 20.792 10.0651L16.7143 14.1429M14.1429 21.8571L21.8571 14.1429"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
}

import { Box } from "@mui/material";

interface Props {
  color?: string;
}

export default function ({ color }: Props) {
  return (
    <Box
      sx={{
        height: "36px",
        width: "36px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="36" height="36" rx="6" fill="#7E2AEA" />
        <path
          d="M25.3333 10.3047H10.6667C10.2985 10.3047 10 10.6412 10 11.0563V24.5858C10 25.0009 10.2985 25.3374 10.6667 25.3374H25.3333C25.7015 25.3374 26 25.0009 26 24.5858V11.0563C26 10.6412 25.7015 10.3047 25.3333 10.3047Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.9102 13.2109H23.8192"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.9102 16.125H20.1829"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
}

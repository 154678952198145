import { produce } from "immer";
import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";

import type { User, UserAccount } from "@frontend/kitui";

type Privilege = {
  amount: number;
  created_at: string;
  id: string;
  privilege_id: string;
  privilege_name: string;
};

export type OriginalUserAccount = {
  created_at: string;
  deleted: boolean;
  email: string;
  id: string;
  privileges: Record<string, Privilege>;
  privilege_name: string;
};

interface UserStore {
  userId: string | null;
  user: User | null;
  userAccount: OriginalUserAccount | null;
  customerAccount: UserAccount | null;
}

const initialState: UserStore = {
  userId: null,
  user: null,
  userAccount: null,
  customerAccount: null,
};

export const useUserStore = create<UserStore>()(
  persist(
    devtools((set, get) => initialState, {
      name: "User",
      enabled: process.env.NODE_ENV === "development",
      trace: process.env.NODE_ENV === "development",
    }),
    {
      version: 2,
      name: "user",
      partialize: (state) => ({
        userId: state.userId,
        user: state.user,
      }),
      migrate: (persistedState, version) => ({
        ...(persistedState as UserStore),
        user: null,
      }),
    },
  ),
);

export const setUserId = (userId: string | null) =>
  useUserStore.setState({ userId });
export const setUser = (user: User) =>
  useUserStore.setState(
    produce<UserStore>((state) => {
      state.user = user;
    }),
  );

export const clearUserData = () => useUserStore.setState({ ...initialState });

export const setUserAccount = (userAccount: OriginalUserAccount) =>
  useUserStore.setState({ userAccount });

export const setCustomerAccount = (customerAccount: UserAccount) =>
  useUserStore.setState({ customerAccount });

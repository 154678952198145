import { QuizConfig } from "@frontend/squzanswerer";
import ChartPieIcon from "@icons/ChartPieIcon";
import ContactBookIcon from "@icons/ContactBookIcon";
import FlowArrowIcon from "@icons/FlowArrowIcon";
import LayoutIcon from "@icons/LayoutIcon";
import QuestionIcon from "@icons/QuestionIcon";

export const quizSetupSteps = [
  {
    stepperText: "Настройка стартовой страницы",
    sidebarText: "Стартовая страница",
    sidebarIcon: LayoutIcon,
  },
  {
    stepperText: "Задайте вопросы",
    sidebarText: "Вопросы",
    sidebarIcon: QuestionIcon,
  },
  {
    stepperText: "Настройте авторезультаты",
    sidebarText: "Результаты",
    sidebarIcon: ChartPieIcon,
  },
  {
    stepperText: "Настройте форму контактов",
    sidebarText: "Форма контактов",
    sidebarIcon: ContactBookIcon,
  },
  {
    stepperText: "Установите quiz",
    sidebarText: "Установка quiz",
    sidebarIcon: FlowArrowIcon,
  },
] as const;

export const maxQuizSetupSteps = quizSetupSteps.length;

export type QuizStartpageType = "standard" | "expanded" | "centered" | null;

export type QuizStartpageAlignType = "left" | "right" | "center";

export type QuizType = "quiz" | "form" | null;

export type QuizResultsType = true | null;

export type QuizTheme =
  | "StandardTheme"
  | "StandardDarkTheme"
  | "PinkTheme"
  | "PinkDarkTheme"
  | "BlackWhiteTheme"
  | "OliveTheme"
  | "YellowTheme"
  | "GoldDarkTheme"
  | "PurpleTheme"
  | "BlueTheme"
  | "BlueDarkTheme"
  | "Design1"
  | "Design2"
  | "Design3"
  | "Design4"
  | "Design5"
  | "Design6"
  | "Design7"
  | "Design8"
  | "Design9"
  | "Design10";

export enum QuizMetricType {
  yandex = "yandexMetricsNumber",
  vk = "vkMetricsNumber",
}

export type FormContactFieldName = "name" | "email" | "phone" | "text" | "address";

export type FieldSettingsDrawerState = {
  field: FormContactFieldName | "all" | "";
  isEdit: boolean;
};

export const defaultQuizConfig: QuizConfig = {
  spec: undefined,
  type: null,
  noStartPage: false,
  startpageType: null,
  results: null,
  haveRoot: null,
  theme: "StandardTheme",
  design: false,
  resultInfo: {
    when: "",
    share: false,
    replay: false,
    theme: "",
    reply: "",
    replname: "",
    showResultForm: "after",
  },
  startpage: {
    description: "",
    button: "",
    position: "left",
    favIcon: null,
    logo: null,
    originalLogo: null,
    background: {
      type: "image",
      desktop: null,
      originalDesktop: null,
      mobile: null,
      originalMobile: null,
      video: null,
      cycle: false,
    },
  },
  info: {
    phonenumber: "",
    clickable: false,
    orgname: "",
    site: "",
    law: "",
  },
  formContact: {
    title: "",
    desc: "",
    fields: {
      name: {
        text: "",
        innerText: "",
        key: "",
        required: false,
        used: true,
      },
      email: {
        text: "",
        innerText: "",
        key: "",
        required: false,
        used: true,
      },
      phone: {
        text: "",
        innerText: "",
        key: "",
        required: false,
        used: true,
      },
      text: {
        text: "",
        innerText: "",
        key: "",
        required: false,
        used: false,
      },
      address: {
        text: "",
        innerText: "",
        key: "",
        required: false,
        used: false,
      },
    },
    button: "",
  },
  meta: "",
  antifraud: true,
  showfc: true,
  yandexMetricsNumber: undefined,
  vkMetricsNumber: undefined,
};

import { QuizQuestionDate } from "@frontend/squzanswerer";
import { QUIZ_QUESTION_BASE } from "./base";

export const QUIZ_QUESTION_DATE: Omit<QuizQuestionDate, "id" | "backendId"> = {
  ...QUIZ_QUESTION_BASE,
  type: "date",
  content: {
    ...QUIZ_QUESTION_BASE.content,
    required: false,
    innerNameCheck: false,
    innerName: "",
    dateRange: false,
    time: false,
  },
};

import { AnyTypedQuizQuestion } from "@frontend/squzanswerer";
import { UntypedQuizQuestion } from "@model/questionTypes/shared";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

export type QuestionsStore = {
  questions: (AnyTypedQuizQuestion | UntypedQuizQuestion)[];
};

const initialState: QuestionsStore = {
  questions: [],
};

export const useQuestionsStore = create<QuestionsStore>()(
  devtools(() => initialState, {
    name: "QuestionsStore",
    enabled: process.env.NODE_ENV === "development",
    trace: process.env.NODE_ENV === "development",
    actionsBlacklist: "ignored",
  })
);

import { Box } from "@mui/material";

interface Props {
  color?: string;
}

export default function ({ color }: Props) {
  return (
    <Box
      sx={{
        height: "36px",
        width: "36px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="36" height="36" rx="6" fill="#7E2AEA" />
        <circle
          cx="4"
          cy="4"
          r="4"
          transform="matrix(-1 0 0 1 22 9)"
          stroke="white"
          strokeWidth="1.5"
        />
        <path
          d="M11 22.9347C11 22.0743 11.5409 21.3068 12.3511 21.0175V21.0175C16.004 19.7128 19.996 19.7128 23.6489 21.0175V21.0175C24.4591 21.3068 25 22.0743 25 22.9347V24.2502C25 25.4376 23.9483 26.3498 22.7728 26.1818L21.8184 26.0455C19.2856 25.6837 16.7144 25.6837 14.1816 26.0455L13.2272 26.1818C12.0517 26.3498 11 25.4376 11 24.2502V22.9347Z"
          stroke="white"
          strokeWidth="1.5"
        />
      </svg>
    </Box>
  );
}

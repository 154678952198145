import { AnyTypedQuizQuestion } from "@frontend/squzanswerer";
import { defaultQuestionByType } from "../../constants/default";
import { nanoid } from "nanoid";

export type QuestionType =
  | "variant"
  | "images"
  | "varimg"
  | "emoji"
  | "text"
  | "select"
  | "date"
  | "number"
  | "file"
  | "page"
  | "rating"
  | "result";

/** Type that comes from server */
export interface RawQuestion {
  /** Id of created question */
  id: number;
  /** relation to quiz */
  quiz_id: number;
  /** title of question. max 512 length */
  title: string;
  /** description of question */
  description: string;
  /** status of question. allow only text, select, file, variant, images, varimg, emoji, date, number, page, rating */
  type: QuestionType;
  /** user must pass this question */
  required: boolean;
  /** true if question is deleted */
  deleted: boolean;
  /** page if question */
  page: number;
  /** serialized json of created question */
  content: string;
  /** version of quiz */
  version: number;
  /** array of previous versions of quiz */
  parent_ids: number[];
  created_at: string;
  updated_at: string;
}

export function rawQuestionToQuestion(rawQuestion: RawQuestion): AnyTypedQuizQuestion {
  let content = defaultQuestionByType[rawQuestion.type].content;
  const frontId = nanoid();

  try {
    content = JSON.parse(rawQuestion.content);
    if (content.id.length === 0 || content.id.length === undefined) content.id = frontId;
  } catch (error) {
    console.warn("Cannot parse question content from string, using default content", error);
  }

  return {
    backendId: rawQuestion.id,
    id: frontId,
    description: rawQuestion.description,
    page: rawQuestion.page,
    quizId: rawQuestion.quiz_id,
    required: rawQuestion.required,
    title: rawQuestion.title,
    type: rawQuestion.type,
    expanded: true,
    openedModalSettings: false,
    deleted: false,
    deleteTimeoutId: 0,
    content,
  } as AnyTypedQuizQuestion;
}

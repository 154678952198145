import { createTicket as createTicketRequest } from "@frontend/kitui";

import { makeRequest } from "@api/makeRequest";

import { parseAxiosError } from "@utils/parse-error";

import type {
  SendTicketMessageRequest,
  CreateTicketResponse,
} from "@frontend/kitui";

type SendFileResponse = {
  message: string;
};

const API_URL = `${process.env.REACT_APP_DOMAIN}/heruvym/v1.0.0`;

export const sendTicketMessage = async (
  ticketId: string,
  message: string,
): Promise<[null, string?]> => {
  try {
    const sendTicketMessageResponse = await makeRequest<
      SendTicketMessageRequest,
      null
    >({
      url: `${API_URL}/send`,
      method: "POST",
      useToken: true,
      body: { ticket: ticketId, message: message, lang: "ru", files: [] },
    });

    return [sendTicketMessageResponse];
  } catch (nativeError) {
    const [error] = parseAxiosError(nativeError);

    return [null, `Не удалось отправить сообщение. ${error}`];
  }
};

export const shownMessage = async (id: string): Promise<[null, string?]> => {
  try {
    const shownMessageResponse = await makeRequest<{ id: string }, null>({
      url: `${API_URL}/shown`,
      method: "POST",
      useToken: true,
      body: { id },
    });

    return [shownMessageResponse];
  } catch (nativeError) {
    const [error] = parseAxiosError(nativeError);

    return [null, `Не удалось прочесть сообщение. ${error}`];
  }
};

export const sendFile = async (
  ticketId: string,
  file: File,
): Promise<[SendFileResponse | null, string?]> => {
  try {
    const body = new FormData();

    body.append(file.name, file);
    body.append("ticket", ticketId);

    const sendResponse = await makeRequest<FormData, SendFileResponse>({
      method: "POST",
      url: `${API_URL}/sendFiles`,
      body,
    });

    return [sendResponse];
  } catch (nativeError) {
    const [error] = parseAxiosError(nativeError);

    return [null, `Не удалось отправить файл. ${error}`];
  }
};

export const createTicket = async (
  message: string,
  useToken: boolean,
): Promise<[CreateTicketResponse | null, string?]> => {
  try {
    const createdTicket = await createTicketRequest({
      url: `${process.env.REACT_APP_DOMAIN}/heruvym/v1.0.0/create`,
      body: { Title: "Unauth title", Message: message },
      useToken,
    });

    return [createdTicket];
  } catch (nativeError) {
    const [error] = parseAxiosError(nativeError);

    return [null, `Не удалось создать тикет. ${error}`];
  }
};

import { Box } from "@mui/material";

interface Props {
  height: string;
  width: string;
  color: string;
  transform: string;
}

export default function CollapseMenuIcon({
  height,
  width,
  color,
  transform,
}: Props) {
  return (
    <Box
      sx={{
        borderRadius: "6px",
        height,
        width,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transform,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M12.5 3L7.5 8L12.5 13"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.5 3L2.5 8L7.5 13"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
}

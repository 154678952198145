import React from "react";
import Box from "@mui/material/Box";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import SectionStyled from "./SectionStyled";
import { styled } from "@mui/material/styles";
import Icon1 from "./images/icons/Handshake";
import Icon2 from "./images/icons/UserCircle";
import Icon3 from "./images/icons/DoneIcon";

const BoxPluses = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    // alignItems: 'center',
    // textAlign: 'center',
    width: "100%",
  },
}));
const BoxCard = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    // alignItems: 'center',
  },
}));

const BoxText = styled("div")(({ theme }) => ({
  [theme.breakpoints.down(600)]: {
    marginBottom: "20px",
  },
}));

export default function Component() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));
  return (
    <SectionStyled
      tag={"section"}
      bg={"#7e2aea"}
      mwidth={"1160px"}
      sxsect={{}}
      sxcont={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        height: "100%",
        color: "#ffffff",
        padding: isMobile
          ? "60px 16px 32px 16px"
          : isTablet
            ? "55px 40px 63px 40px"
            : "70px 0 65px 0",
      }}
    >
      <BoxText>
        <Typography
          sx={{ marginBottom: isMobile ? 0 : "40px" }}
          variant={"h6"}
          fontSize={"36px"}
        >
          В чем плюсы для бизнеса
        </Typography>
      </BoxText>
      <BoxPluses
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: isMobile ? "17px" : undefined,
        }}
      >
        <BoxCard
          sx={{
            maxWidth: isTablet ? "285px" : "290px",
            height: "151px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <Icon1 />
          <Typography fontSize={"18px"} lineHeight={"22.33px"}>
            Человек ощущает заботу и склоняется к выбору вашей компании
          </Typography>
        </BoxCard>
        <BoxCard
          sx={{
            maxWidth: isTablet ? "285px" : "290px",
            height: "151px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <Icon2 />
          <Typography
            fontSize={"18px"}
            lineHeight={"22.33px"}
            paddingBottom={"18px"}
          >
            Получаете заинтересованного, квалифицированного клиента
          </Typography>
        </BoxCard>
        <BoxCard
          sx={{
            maxWidth: isTablet ? "307px" : "346px",
            height: "151px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <Icon3 />
          <Typography
            fontSize={"18px"}
            lineHeight={"22.33px"}
            paddingBottom={"18px"}
          >
            Увеличиваете качество цифрового общения и конверсию в продажи
          </Typography>
        </BoxCard>
      </BoxPluses>
    </SectionStyled>
  );
}

import { Box } from "@mui/material";

interface Props {
  color?: string;
}

export default function ({ color }: Props) {
  return (
    <Box
      sx={{
        height: "36px",
        width: "36px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="36" height="36" rx="6" fill="#7E2AEA" />
        <path
          d="M18 20.237H11.0333C9.63421 20.237 8.5 19.1027 8.5 17.7036V17.7036C8.5 16.3045 9.63421 15.1703 11.0333 15.1703L18.4318 15.1703M24.0455 20.237H24.9667C26.3658 20.237 27.5 19.1027 27.5 17.7036V17.7036C27.5 16.3045 26.3658 15.1703 24.9667 15.1703H23.6136"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx="20.9561"
          cy="17.892"
          r="3.58889"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
}

export const TreeStructure = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
  >
    <path
      d="M4.72656 7.53125H1.91406C1.6034 7.53125 1.35156 7.78309 1.35156 8.09375V10.9062C1.35156 11.2169 1.6034 11.4688 1.91406 11.4688H4.72656C5.03722 11.4688 5.28906 11.2169 5.28906 10.9062V8.09375C5.28906 7.78309 5.03722 7.53125 4.72656 7.53125Z"
      stroke="#9A9AAF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.8516 2.36513H11.4766C11.1659 2.36513 10.9141 2.61697 10.9141 2.92763V6.30263C10.9141 6.61329 11.1659 6.86513 11.4766 6.86513H14.8516C15.1622 6.86513 15.4141 6.61329 15.4141 6.30263V2.92763C15.4141 2.61697 15.1622 2.36513 14.8516 2.36513Z"
      stroke="#9A9AAF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.8516 12.1349H11.4766C11.1659 12.1349 10.9141 12.3867 10.9141 12.6974V16.0724C10.9141 16.383 11.1659 16.6349 11.4766 16.6349H14.8516C15.1622 16.6349 15.4141 16.383 15.4141 16.0724V12.6974C15.4141 12.3867 15.1622 12.1349 14.8516 12.1349Z"
      stroke="#9A9AAF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.28906 9.5H8.10156"
      stroke="#9A9AAF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9141 14.3849H9.78908C9.56721 14.3858 9.34736 14.3428 9.1422 14.2583C8.93704 14.1738 8.75064 14.0496 8.59376 13.8927C8.43688 13.7358 8.31261 13.5494 8.22814 13.3443C8.14366 13.1391 8.10065 12.9192 8.10158 12.6974V6.30264C8.10065 6.08078 8.14366 5.86092 8.22814 5.65576C8.31261 5.45061 8.43688 5.26421 8.59376 5.10733C8.75064 4.95044 8.93704 4.82618 9.1422 4.7417C9.34736 4.65723 9.56721 4.61421 9.78908 4.61514H10.9141"
      stroke="#9A9AAF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import { makeRequest } from "@api/makeRequest";

import { parseAxiosError } from "@utils/parse-error";

type ActivatePromocodeRequest = { codeword: string } | { fastLink: string };
type ActivatePromocodeResponse = { greetings: string };

const API_URL = `${process.env.REACT_APP_DOMAIN}/codeword/promocode`;

export const activatePromocode = async (
  promocode: string,
): Promise<[string | null, string?]> => {
  try {
    const response = await makeRequest<
      ActivatePromocodeRequest,
      ActivatePromocodeResponse
    >({
      method: "POST",
      url: `${API_URL}/activate`,
      body: { codeword: promocode },
      contentType: true,
    });

    return [response.greetings];
  } catch (nativeError) {
    const [error] = parseAxiosError(nativeError);

    return [null, `Ошибка при активации промокода. ${error}`];
  }
};

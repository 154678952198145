import { QUIZ_QUESTION_BASE } from "./base";

import type { QuizQuestionNumber } from "@frontend/squzanswerer";

export const QUIZ_QUESTION_NUMBER: Omit<QuizQuestionNumber, "id" | "backendId"> = {
  ...QUIZ_QUESTION_BASE,
  type: "number",
  content: {
    ...QUIZ_QUESTION_BASE.content,
    required: false,
    innerNameCheck: false,
    innerName: "",
    range: "0—100",
    defaultValue: 0,
    step: 1,
    steps: 5,
    start: 0,
    chooseRange: false,
    form: "star",
  },
};

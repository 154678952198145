import { Box, useTheme } from "@mui/material";

interface Props {
  bgcolor?: string;
  height: string;
  width: string;
  color: string;
}

export default function LayoutIconOld({
  bgcolor,
  height,
  width,
  color,
}: Props) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: bgcolor,
        height,
        width,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "6px",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="35"
        viewBox="0 0 35 35"
        fill="none"
      >
        <path
          d="M14.2188 14.2188V28.4375"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.375 14.2188H30.625"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.5312 6.5625H5.46875C4.86469 6.5625 4.375 7.05219 4.375 7.65625V27.3438C4.375 27.9478 4.86469 28.4375 5.46875 28.4375H29.5312C30.1353 28.4375 30.625 27.9478 30.625 27.3438V7.65625C30.625 7.05219 30.1353 6.5625 29.5312 6.5625Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
}

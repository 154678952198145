import { FC, SVGProps } from "react";

export const Settings: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    {...props}
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.10938 18.8906C4.24688 18.0281 4.81875 16.2187 4.37813 15.1594C3.9375 14.1 2.25 13.1719 2.25 12C2.25 10.8281 3.91875 9.9375 4.37813 8.84063C4.8375 7.74375 4.24688 5.97187 5.10938 5.10938C5.97187 4.24688 7.78125 4.81875 8.84063 4.37813C9.9 3.9375 10.8281 2.25 12 2.25C13.1719 2.25 14.0625 3.91875 15.1594 4.37813C16.2562 4.8375 18.0281 4.24688 18.8906 5.10938C19.7531 5.97187 19.1813 7.78125 19.6219 8.84063C20.0625 9.9 21.75 10.8281 21.75 12C21.75 13.1719 20.0813 14.0625 19.6219 15.1594C19.1625 16.2562 19.7531 18.0281 18.8906 18.8906C18.0281 19.7531 16.2187 19.1813 15.1594 19.6219C14.1 20.0625 13.1719 21.75 12 21.75C10.8281 21.75 9.9375 20.0813 8.84063 19.6219C7.74375 19.1625 5.97187 19.7531 5.10938 18.8906Z"
      stroke="#974BFA"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 18C12.6213 18 13.125 17.4963 13.125 16.875C13.125 16.2537 12.6213 15.75 12 15.75C11.3787 15.75 10.875 16.2537 10.875 16.875C10.875 17.4963 11.3787 18 12 18Z"
      fill="currentColor"
    />
    <path
      d="M12 13.5V12.75C12.5192 12.75 13.0267 12.596 13.4584 12.3076C13.8901 12.0192 14.2265 11.6092 14.4252 11.1295C14.6239 10.6499 14.6758 10.1221 14.5746 9.61289C14.4733 9.10369 14.2233 8.63596 13.8562 8.26885C13.489 7.90173 13.0213 7.65173 12.5121 7.55044C12.0029 7.44915 11.4751 7.50114 10.9955 7.69982C10.5158 7.8985 10.1058 8.23495 9.81739 8.66663C9.52895 9.09831 9.375 9.60583 9.375 10.125"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

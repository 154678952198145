import { QuizConfig } from "@frontend/squzanswerer";
import { defaultQuizConfig } from "@model/quizSettings";
import { nanoid } from "nanoid";

export interface Quiz {
  /** Stable id, generated on client */
  id: string;
  /** Id of created quiz */
  backendId: number;
  /** string id for customers */
  qid: string;
  /** true if quiz deleted */
  deleted: boolean;
  /** true if quiz archived */
  archived: boolean;
  /** set true for save deviceId */
  fingerprinting: boolean;
  /** set true for allow user to repeat quiz */
  repeatable: boolean;
  /** set true for save statistic of incomplete quiz passing */
  note_prevented: boolean;
  /** set true for mail notification for each quiz passing */
  mail_notifications: boolean;
  /** set true for save statistics only for unique quiz passing */
  unique_answers: boolean;
  /** name of quiz. max 280 length */
  name: string;
  /** description of quiz */
  description: string;
  /** quiz config*/
  config: QuizConfig;
  /** status of quiz. allow only '', 'draft', 'template', 'stop', 'start' */
  status: string;
  /** limit is count of max quiz passing */
  limit: number;
  /** last time when quiz is valid. timestamp in seconds */
  due_to: number;
  /** seconds to pass quiz */
  time_of_passing: number;
  /** true if it is allowed for pause quiz */
  pausable: boolean;
  /** version of quiz */
  version: number;
  /** version comment to version of quiz */
  version_comment: string;
  /** array of previous versions of quiz */
  parent_ids: number[];
  created_at: string;
  updated_at: string;
  /** count of questions */
  questions_count: number;
  /** count passings */
  passed_count: number;
  session_count: number;
  /** average time of passing */
  average_time: number;
  /** set true if squiz realize group functionality */
  super: boolean;
  /** group of new quiz */
  group_id: number;
}

/** Type that comes from server */
export interface RawQuiz {
  /** Id of created quiz */
  id: number;
  /** string id for customers */
  qid: string;
  /** true if quiz deleted */
  deleted: boolean;
  /** true if quiz archived */
  archived: boolean;
  /** set true for save deviceId */
  fingerprinting: boolean;
  /** set true for allow user to repeat quiz */
  repeatable: boolean;
  /** set true for save statistic of incomplete quiz passing */
  note_prevented: boolean;
  /** set true for mail notification for each quiz passing */
  mail_notifications: boolean;
  /** set true for save statistics only for unique quiz passing */
  unique_answers: boolean;
  /** name of quiz. max 280 length */
  name: string;
  /** description of quiz */
  description: string;
  /** config of quiz. serialized json for rules of quiz flow */
  config: string;
  /** status of quiz. allow only '', 'draft', 'template', 'stop', 'start' */
  status: string;
  /** limit is count of max quiz passing */
  limit: number;
  /** last time when quiz is valid. timestamp in seconds */
  due_to: number;
  /** seconds to pass quiz */
  time_of_passing: number;
  /** true if it is allowed for pause quiz */
  pausable: boolean;
  /** version of quiz */
  version: number;
  /** version comment to version of quiz */
  version_comment: string;
  /** array of previous versions of quiz */
  parent_ids: number[];
  created_at: string;
  updated_at: string;
  /** count of questions */
  questions_count: number;
  /** count passings */
  passed_count: number;
  session_count: number;
  /** average time of passing */
  average_time: number;
  /** set true if squiz realize group functionality */
  super: boolean;
  /** group of new quiz */
  group_id: number;
}

export function rawQuizToQuiz(rawQuiz: RawQuiz): Quiz {
  let config = defaultQuizConfig;

  try {
    config = JSON.parse(rawQuiz.config);
  } catch (error) {
    console.warn("Cannot parse quiz config from string, using default config", error);
  }

  return {
    ...rawQuiz,
    config,
    backendId: rawQuiz.id,
    id: nanoid(),
  };
}

import { Box } from "@mui/material";

interface Props {
  color?: string;
}

export default function ({ color }: Props) {
  return (
    <Box
      sx={{
        height: "59px",
        width: "59px",
        display: "flex",
        borderRadius: "6px",
        background: "#5A1EA8",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <svg
        width="39"
        height="39"
        viewBox="0 0 39 39"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Handshake">
          <path
            id="Vector"
            d="M36.6691 18.5555L32.9063 20.4293L28.0312 11.1059L31.8398 9.20158C32.1229 9.05731 32.4515 9.03079 32.754 9.12782C33.0565 9.22485 33.3084 9.43756 33.4547 9.71955L37.2023 16.8949C37.2783 17.0386 37.3248 17.196 37.3391 17.3578C37.3534 17.5197 37.3352 17.6828 37.2855 17.8375C37.2358 17.9922 37.1557 18.1355 37.0498 18.2588C36.944 18.3821 36.8146 18.4829 36.6691 18.5555V18.5555Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M6.093 20.2769L2.33011 18.3878C2.18523 18.3168 2.0561 18.2174 1.9504 18.0955C1.84471 17.9736 1.76461 17.8317 1.71487 17.6782C1.66513 17.5247 1.64676 17.3628 1.66085 17.202C1.67494 17.0413 1.72121 16.885 1.79691 16.7425L5.54457 9.56714C5.69117 9.28536 5.94199 9.07194 6.24362 8.97234C6.54525 8.87275 6.87384 8.89485 7.15941 9.03394L10.968 10.9382L6.093 20.2769Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_3"
            d="M32.9062 20.4292L30.4688 23.2933L24.8625 28.8995C24.7097 29.0424 24.5246 29.1461 24.3229 29.2018C24.1213 29.2576 23.9092 29.2636 23.7047 29.2194L14.8687 27.0104C14.7073 26.9655 14.5569 26.8877 14.427 26.7819L6.09375 20.2769"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_4"
            d="M30.4692 23.2935L23.7661 18.4185L21.8161 19.881C20.9712 20.5115 19.9453 20.8522 18.8911 20.8522C17.8369 20.8522 16.8109 20.5115 15.9661 19.881L15.1434 19.2564C15.0043 19.1506 14.8892 19.0165 14.8058 18.8629C14.7224 18.7094 14.6726 18.5398 14.6596 18.3656C14.6467 18.1913 14.6709 18.0163 14.7306 17.852C14.7904 17.6878 14.8844 17.5382 15.0063 17.413L20.9782 11.4564C21.0904 11.3447 21.2234 11.2563 21.3698 11.1962C21.5162 11.1361 21.673 11.1054 21.8313 11.106H28.0317"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_5"
            d="M11.0586 10.9383L18.8738 8.65318C19.1515 8.57354 19.4485 8.59514 19.7117 8.71412L24.9828 11.1059"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_6"
            d="M17.0625 32.434L12.477 31.2762C12.2901 31.234 12.1171 31.1448 11.9742 31.0172L8.53125 28.0312"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </Box>
  );
}

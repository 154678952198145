import {
  AnyTypedQuizQuestion,
  QuestionBranchingRule,
  QuestionBranchingRuleMain,
  QuizQuestionResult,
} from "@frontend/squzanswerer";
import {
  clearRuleForAll,
  createResult,
  deleteQuestion,
  getQuestionByContentId,
  updateQuestion,
} from "@root/questions/actions";
import { useQuestionsStore } from "@root/questions/store";
import { updateRootContentId } from "@root/quizes/actions";
import { getCurrentQuiz } from "@root/quizes/hooks";

//Всё здесь нужно сделать последовательно. И пусть весь мир ждёт.

export const DeleteFunction = async (questionId: string) => {
  const questions = useQuestionsStore.getState().questions;
  const quiz = getCurrentQuiz();
  const question = questions.find((q) => q.id === questionId);

  if (!question) return;
  if (!quiz) throw new Error("Quiz is null");

  if (question.type !== null) {
    if (question.content.rule.parentId === "root") {
      //удалить из стора root и очистить rule всем вопросам
      updateRootContentId(quiz.id, "");
      await clearRuleForAll();
      await deleteQuestion(question.id);
    } else if (question.content.rule.parentId.length > 0) {
      //удалить из стора вопрос из дерева и очистить его потомков
      const clearQuestions = [] as string[];

      const parentQuestion = getQuestionByContentId(question.content.rule.parentId);
      let startCountParentChildren = parentQuestion?.type ? parentQuestion.content.rule.children : null;

      //записываем потомков , а их результаты удаляем
      const getChildren = (parentQuestion: AnyTypedQuizQuestion) => {
        questions.forEach((targetQuestion) => {
          if (targetQuestion.type !== null && targetQuestion.content.rule.parentId === parentQuestion.content.id) {
            //если у вопроса совпал родитель с родителем => он потомок, в кучу его
            if (targetQuestion.type !== "result" && targetQuestion.type !== null) {
              if (!clearQuestions.includes(targetQuestion.content.id)) clearQuestions.push(targetQuestion.content.id);
              getChildren(targetQuestion); //и ищем его потомков
            }
          }
        });
      };
      getChildren(question);
      //чистим потомков от инфы ветвления
      await Promise.allSettled(
        clearQuestions.map((id) => {
          updateQuestion(id, (question) => {
            question.content.rule.parentId = "";
            question.content.rule.children = [];
            question.content.rule.main = [];
            question.content.rule.default = "";
          });
        })
      );

      if (!parentQuestion?.type) {
        return;
      }

      const parentChildren = [...parentQuestion.content.rule.children];

      if (parentChildren.includes(question.content.id))
        parentChildren.splice(parentQuestion.content.rule.children.indexOf(question.content.id), 1);

      const main = parentQuestion.content.rule.main.filter(
        (data: QuestionBranchingRuleMain) => data.next !== question.content.id
      ); //удаляем условия перехода от родителя к этому вопросу
      const defaultValue =
        parentQuestion.content.rule.parentId === question.content.id ? "" : parentQuestion.content.rule.parentId;

      //чистим rule родителя
      const newRule: QuestionBranchingRule = {
        main,
        default: defaultValue,
        children: parentChildren,
        parentId: parentQuestion.content.rule.parentId,
      };

      await updateQuestion(question.content.rule.parentId, (PQ) => {
        PQ.content.rule = newRule;
      });
      await deleteQuestion(question.id);

      const parentResult = questions.find(
        (q) => q.type === "result" && q.content.rule.parentId === parentQuestion.content.id
      );

      //сделать результ родителя видимым если у него не осталось потомков

      if (startCountParentChildren.length === 1) {
        if (parentResult?.type) {
          await updateQuestion<QuizQuestionResult>(parentResult.content.id, (item) => {
            item.content.usage = true;
          });
        } else {
          //почему-то не существует результа у родителя. Создаём. Новосозданные результы видны сразу
          await createResult(quiz.backendId, parentQuestion.content.id);
        }
      }
    }

    await deleteQuestion(question.id);

    const result = questions.find((q) => q.type === "result" && q.content.rule.parentId === question.content.id);
    if (result) await deleteQuestion(result.id);
  }
};

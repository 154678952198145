import { Box, Modal, Popper } from "@mui/material";

import Sidebar from "@ui_kit/Sidebar/Sidebar";

type SidebarModalProps = {
  open: boolean;
  handleClick: () => void;
  changePage: (step: number) => void;
  anchorEl: HTMLElement | null;
  id: string;
};
export const SidebarModal = ({
  open,
  handleClick,
  changePage,
  id,
  anchorEl,
}: SidebarModalProps) => {
  return (
    <Popper open={open} id={id} anchorEl={anchorEl}>
      <Box
        onClick={handleClick}
        sx={{
          outline: "none",
          overflow: "hidden",
          maxWidth: "230px",
          maxHeight: "400px",
          width: "100%",
          bgcolor: "background.paper",
          borderRadius: "5px",
          boxShadow: 24,
          p: 0,
        }}
      >
        <Sidebar changePage={changePage} disableCollapse />
      </Box>
    </Popper>
  );
};

import { Box } from "@mui/material";

interface Props {
  height: string;
  width: string;
  color: string;
}

export default function PuzzlePieceIcon({ height, width, color }: Props) {
  return (
    <Box
      sx={{
        borderRadius: "6px",
        height,
        width,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="35"
        viewBox="0 0 35 35"
        fill="none"
      >
        <path
          d="M8.74976 29.5313C8.45968 29.5313 8.18148 29.416 7.97636 29.2109C7.77125 29.0058 7.65601 28.7276 7.65601 28.4375V22.5996C7.05597 22.8859 6.39095 23.0085 5.72828 22.9551C5.16718 22.9139 4.62206 22.7495 4.13172 22.4736C3.64138 22.1977 3.21788 21.8172 2.89135 21.359C2.56483 20.9008 2.3433 20.3763 2.24255 19.8228C2.14179 19.2693 2.16429 18.7004 2.30842 18.1565C2.45256 17.6127 2.7148 17.1073 3.07648 16.6763C3.43816 16.2454 3.89038 15.8994 4.40097 15.6631C4.91155 15.4268 5.46795 15.306 6.03056 15.3092C6.59316 15.3124 7.14815 15.4396 7.65601 15.6816V9.84376C7.65601 9.55368 7.77125 9.27548 7.97636 9.07036C8.18148 8.86524 8.45968 8.75001 8.74976 8.75001H15.1345C14.8483 8.14996 14.7257 7.48494 14.7791 6.82227C14.8203 6.26117 14.9847 5.71605 15.2606 5.22571C15.5364 4.73538 15.917 4.31187 16.3752 3.98535C16.8333 3.65882 17.3578 3.4373 17.9113 3.33654C18.4649 3.23579 19.0338 3.25828 19.5776 3.40242C20.1215 3.54656 20.6268 3.8088 21.0578 4.17047C21.4888 4.53215 21.8347 4.98438 22.071 5.49496C22.3073 6.00555 22.4282 6.56194 22.425 7.12455C22.4218 7.68716 22.2946 8.24214 22.0525 8.75001H28.4373C28.7273 8.75001 29.0055 8.86524 29.2107 9.07036C29.4158 9.27548 29.531 9.55368 29.531 9.84376V15.6816C28.931 15.3954 28.2659 15.2728 27.6033 15.3262C27.0422 15.3674 26.4971 15.5318 26.0067 15.8077C25.5164 16.0835 25.0929 16.4641 24.7664 16.9223C24.4398 17.3804 24.2183 17.9049 24.1176 18.4585C24.0168 19.012 24.0393 19.5809 24.1834 20.1247C24.3276 20.6686 24.5898 21.174 24.9515 21.6049C25.3132 22.0359 25.7654 22.3818 26.276 22.6181C26.7866 22.8544 27.343 22.9753 27.9056 22.9721C28.4682 22.9689 29.0231 22.8417 29.531 22.5996V28.4375C29.531 28.7276 29.4158 29.0058 29.2107 29.2109C29.0055 29.416 28.7273 29.5313 28.4373 29.5313H8.74976Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
}

import { cartApi } from "@api/cart";
import { useUserStore } from "@/stores/user";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { redirect, useNavigate, useSearchParams } from "react-router-dom";
import { calcTimeOfReadyPayCart, cancelPayCartProcess, startPayCartProcess, useNotEnoughMoneyAmount } from "@/stores/notEnoughMoneyAmount";
import { startCC } from "@/stores/cc";

export const useAfterPay = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const userId = useUserStore(store => store.userId)
  const userAccount = useUserStore(state => state.userAccount);
  const siteReadyPayCart = useNotEnoughMoneyAmount(state => state.siteReadyPayCart);

  const purpose = searchParams.get("purpose");
  const paymentUserId = searchParams.get("userid");
  const currentCC = searchParams.get("cc");


  useEffect(() => {
    //Звёзды сошлись, будем оплачивать корзину
    if (paymentUserId && paymentUserId === userId) {

      if (purpose === "paycart") {
        setSearchParams({}, { replace: true });
        if (currentCC) { startCC() }
        (async () => {

          //Проверяем можем ли мы оплатить корзину здесь и сейчас
          const [, payCartError] = await cartApi.pay();

          if (payCartError) {
            //Не получилось купить корзину. Ставим флаг, что сайт в состоянии ожидания пополнения счёта для оплаты
            startPayCartProcess(paymentUserId)
          } else {
            if (currentCC) navigate("/tariffs")
            cancelPayCartProcess()
          }
        })()
      }
    }
  }, [purpose, paymentUserId])

  useEffect(() => {
    if (userId !== null && siteReadyPayCart !== null && siteReadyPayCart[userId] !== undefined) {
      const deadline = siteReadyPayCart[userId]
      if (calcTimeOfReadyPayCart(deadline)) {

        //Время ещё не вышло. У нас стоит флаг покупать корзину если время не вышло.
        (async () => {
          const [, payCartError] = await cartApi.pay();

          if (!payCartError) {
            enqueueSnackbar("Товары успешно приобретены")
            cancelPayCartProcess()
          }
        })()
      }
    }
  }, [userAccount, userId, siteReadyPayCart])
}
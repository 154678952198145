import React, { useState } from "react";
import Box from "@mui/material/Box";
import { SxProps, Typography, useMediaQuery, useTheme } from "@mui/material";
import SectionStyled from "./SectionStyled";
import Button from "@mui/material/Button";
import Desktop1 from "./images/Frame 1171274552.png";
import Desktop2 from "./images/Frame 1171274552-1.png";
import Desktop3 from "./images/Frame 1171274552-2.png";
import Desktop4 from "./images/Frame 1171274552-3.png";
import Desktop5 from "./images/Frame 1171274552-4.png";
import Desktop6 from "./images/Frame 1171274552-5.png";
import Desktop7 from "./images/Frame 1171274552-6.png";
import LandingImg from "./images/LandingImg.png";

interface Props {
  children: React.ReactNode;
  image: string;
  sx?: SxProps;
}

function BoxFich(props: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  const isTabletOnly = useMediaQuery(theme.breakpoints.between(600, 1000));
  return (
    <Box
      sx={{
        maxWidth: isTabletOnly ? "801px" : "500px",
        minHeight: isTabletOnly ? "252px" : "350px",
        backgroundColor: "#252734",
        borderRadius: "12px",
        overflow: "hidden",
        display: "flex",
        flexDirection: isTabletOnly ? "row" : "column",
        justifyContent: isMobile ? "space-between" : undefined,
        height: isMobile ? "450px" : undefined,
        ...props.sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          minHeight: "50%",
          padding: "20px",
          flexWrap: "wrap",
          boxSizing: "border-box",
          flexDirection: isMobile ? "column" : "row",
          gap: "15px",
        }}
      >
        {props.children}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "end",
          mt: "auto",
        }}
      >
        <img src={props.image} width={"96%"} />
      </Box>
    </Box>
  );
}

export default function Component() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));
  const isTabletOnly = useMediaQuery(theme.breakpoints.between(600, 1000));
  return (
    <SectionStyled
      tag={"section"}
      bg={"#333647"}
      mwidth={"1160px"}
      sxcont={{
        display: "flex",
        justifyContent: "space-between",

        padding: isMobile ? "70px 16px" : isTablet ? "80px 40px" : "80px 0",
        color: "#ffffff",
        flexWrap: "wrap",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: isMobile ? "25px" : "120px",
        }}
      >
        <Box
          sx={{
            maxWidth: "400px",
            minHeight: "84px",
            marginBottom: isMobile ? 0 : "-55px",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: "36px",
              fontWeight: "500",
              lineHeight: "43px",
            }}
          >
            Что по фичам?
          </Typography>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "400",
              lineHeight: "21px",
              margin: "20px 0 10px 0",
            }}
          >
            Самый функциональный конструктор квизов
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: isTablet ? "column-reverse" : "row",
            justifyContent: "space-between",
            gap: "50px",
            maxWidth: "1160px",
            minHeight: "500px",
            p: isTablet ? "40px 20px 40px" : "40px",
            background: "#262835",
            borderRadius: "12px",
          }}
        >
          <img
            style={{
              maxWidth: isTablet ? "880px" : "554px",
              width: "100%",
              maxHeight: isTablet ? "666px" : "420px",
              height: "100%",
            }}
            src={LandingImg}
            alt="img"
          />
          <Box sx={{ maxWidth: "461px", width: "100%" }}>
            <Typography
              variant={"h3"}
              sx={{
                fontSize: "36px",
                fontWeight: "500",
                lineHeight: "normal",
              }}
            >
              Удобный конструктор ветвления
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "400",
                lineHeight: "21px",
                m: isTablet ? "20px 0 40px" : "20px 0 98px",
                color: "#ffffffb3",
              }}
            >
              Внутренняя аналитика считает конверсию квиза на каждом шаге,
              показывает развернутую статистику по ответам и пользователям
            </Typography>

            {/*<Button*/}
            {/*  variant={"contained"}*/}
            {/*  sx={{*/}
            {/*    width: "124px",*/}
            {/*    height: "30px",*/}
            {/*    padding: "4px 8px",*/}
            {/*    fontSize: "12px",*/}
            {/*  }}*/}
            {/*>*/}
            {/*  Как использовать*/}
            {/*</Button>*/}

            {/*<Box sx={{ mt: "28px" }}>*/}
            {/*  <Typography*/}
            {/*    sx={{ color: "#9A9AAF", fontSize: "18px", mb: "5px" }}*/}
            {/*  >*/}
            {/*    1. Перетащите вопрос из списка в карту ветвления*/}
            {/*  </Typography>*/}
            {/*  <Typography sx={{ color: "#9A9AAF", fontSize: "18px" }}>*/}
            {/*    2. Добавьте условия{" "}*/}
            {/*  </Typography>*/}
            {/*</Box>*/}
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: isMobile ? "25px" : isTablet ? "40px" : "45px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: isTablet ? "column" : "-moz-initial",
              gap: isMobile ? "25px" : isTablet ? "40px" : "45px",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Box>
              <Typography sx={{ color: "#9A9AAF", fontSize: "18px" }}>
                Другие преимущества:
              </Typography>
              <BoxFich sx={{ mt: isTablet ? "40px" : "75px" }} image={Desktop1}>
                <Box sx={{ maxWidth: isTabletOnly ? "244px" : "174px" }}>
                  <Typography
                    sx={{
                      color: "#7e2aea",
                      fontSize: "24px",
                      fontWeight: "500",
                      lineHeight: "28.44px",
                    }}
                  >
                    01
                  </Typography>
                  <Typography
                    variant={"h6"}
                    sx={{
                      fontSize: "24px",
                      fontWeight: "500",
                      lineHeight: "28.44px",
                    }}
                  >
                    Привязка к вашему домену
                  </Typography>
                </Box>
                <Box sx={{ maxWidth: isTabletOnly ? "300px" : "230px" }}>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "400",
                      lineHeight: "21px",
                    }}
                  >
                    Привяжите квиз к своему брендовому домену и получайте
                    максимальную отдачу от каждой заявки
                  </Typography>
                </Box>
              </BoxFich>
            </Box>
            <BoxFich sx={{ ml: isTablet ? "auto" : "0" }} image={Desktop5}>
              <Box maxWidth={"174px"}>
                <Typography
                  sx={{
                    color: "#7e2aea",
                    fontSize: "24px",
                    fontWeight: "500",
                    lineHeight: "28.44px",
                  }}
                >
                  02
                </Typography>
                <Typography
                  variant={"h6"}
                  sx={{
                    fontSize: "24px",
                    fontWeight: "500",
                    lineHeight: "28.44px",
                  }}
                >
                  Повышение конверсии
                </Typography>
              </Box>
              <Box maxWidth={"233px"}>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "400",
                    lineHeight: "21px",
                  }}
                >
                  Конверсию можно увеличить, добавив тающую скидку, консультанта
                  с подсказками или бонус, который пользователь получит в конце
                  квиза<br></br>(в разработке)
                </Typography>
              </Box>
            </BoxFich>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: isTablet ? "column" : "-moz-initial",
              gap: isMobile ? "25px" : isTablet ? "40px" : "45px",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <BoxFich sx={{ mt: isTablet ? "0" : "75px" }} image={Desktop2}>
              <Box maxWidth={"174px"}>
                <Typography
                  sx={{
                    color: "#7e2aea",
                    fontSize: "24px",
                    fontWeight: "500",
                    lineHeight: "28.44px",
                  }}
                >
                  03
                </Typography>
                <Typography
                  variant={"h6"}
                  sx={{
                    fontSize: "24px",
                    fontWeight: "500",
                    lineHeight: "28.44px",
                  }}
                >
                  Аналитика
                </Typography>
              </Box>
              <Box maxWidth={"230px"}>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "400",
                    lineHeight: "21px",
                  }}
                >
                  Внутренняя аналитика считает конверсию квиза на каждом шаге,
                  показывает развернутую статистику по ответам и пользователям
                </Typography>
              </Box>
            </BoxFich>
            <BoxFich sx={{ ml: isTablet ? "auto" : "0" }} image={Desktop6}>
              <Box maxWidth={"174px"}>
                <Typography
                  sx={{
                    color: "#7e2aea",
                    fontSize: "24px",
                    fontWeight: "500",
                    lineHeight: "28.44px",
                  }}
                >
                  04
                </Typography>
                <Typography
                  variant={"h6"}
                  sx={{
                    fontSize: "24px",
                    fontWeight: "500",
                    lineHeight: "28.44px",
                  }}
                >
                  Расширенные настройки дизайна
                </Typography>
              </Box>
              <Box maxWidth={"230px"}>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "400",
                    lineHeight: "21px",
                  }}
                >
                  Настройте цвета, шрифт и выберите формат стартовой страницы,
                  чтобы сделать свой квиз уникальным
                </Typography>
              </Box>
            </BoxFich>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: isTablet ? "column" : "-moz-initial",
              gap: isMobile ? "25px" : isTablet ? "40px" : "0",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <BoxFich sx={{ mt: isTablet ? "0" : "75px" }} image={Desktop3}>
              <Box maxWidth={"174px"}>
                <Typography
                  sx={{
                    color: "#7e2aea",
                    fontSize: "24px",
                    fontWeight: "500",
                    lineHeight: "28.44px",
                  }}
                >
                  05
                </Typography>
                <Typography
                  variant={"h6"}
                  sx={{
                    fontSize: "24px",
                    fontWeight: "500",
                    lineHeight: "28.44px",
                  }}
                >
                  Режим анкетирования
                </Typography>
              </Box>
              <Box maxWidth={"230px"}>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "400",
                    lineHeight: "21px",
                  }}
                >
                  Самый удобный инструмент для проведения исследований
                  аудитории, больше вас не сдерживают никакие ограничения.
                </Typography>
              </Box>
            </BoxFich>
            <BoxFich sx={{ ml: isTablet ? "auto" : "0" }} image={Desktop7}>
              <Box maxWidth={"174px"}>
                <Typography
                  sx={{
                    color: "#7e2aea",
                    fontSize: "24px",
                    fontWeight: "500",
                    lineHeight: "28.44px",
                  }}
                >
                  06
                </Typography>
                <Typography
                  variant={"h6"}
                  sx={{
                    fontSize: "24px",
                    fontWeight: "500",
                    lineHeight: "28.44px",
                  }}
                >
                  А/Б тестирование
                </Typography>
                <Typography>(в разработке)</Typography>
              </Box>
              <Box maxWidth={"230px"}>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "400",
                    lineHeight: "21px",
                  }}
                >
                  Без программистов и сложных настроек - создавайте разные
                  версии квизов и сравнивайте их эффективность прямо внутри
                  Опросника
                </Typography>
              </Box>
            </BoxFich>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: isTablet ? "column" : "-moz-initial",
              gap: isMobile ? "25px" : isTablet ? "40px" : "45px",
              justifyContent: "space-between",
              alignItems: "flex-start",
              mt: isTablet ? "0" : "75px",
            }}
          >
            <BoxFich image={Desktop4}>
              <Box maxWidth={"174px"}>
                <Typography
                  sx={{
                    color: "#7e2aea",
                    fontSize: "24px",
                    fontWeight: "500",
                    lineHeight: "28.44px",
                  }}
                >
                  07
                </Typography>
                <Typography
                  variant={"h6"}
                  sx={{
                    fontSize: "24px",
                    fontWeight: "500",
                    lineHeight: "28.44px",
                  }}
                >
                  Интерактивные тесты
                </Typography>
                <Typography>(в разработке)</Typography>
              </Box>
              <Box maxWidth={"234px"}>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "400",
                    lineHeight: "21px",
                  }}
                >
                  Тест увеличит вовлеченность аудитории, покажет правильные или
                  неправильные ответы и все это в игровой форме
                </Typography>
              </Box>
            </BoxFich>

            <Box sx={{ maxWidth: "500px", minHeight: "350px" }}>
              {/*{isMobile && (*/}
              {/*    <Button*/}
              {/*        sx={{*/}
              {/*            width: "100%",*/}
              {/*            height: "44px",*/}
              {/*            borderRadius: "8px",*/}
              {/*            border: "1px solid #FFF",*/}
              {/*            color: "white",*/}
              {/*            mb: "40px",*/}
              {/*            background: "transparent",*/}
              {/*        }}*/}
              {/*    >*/}
              {/*        Все возможности сервиса*/}
              {/*    </Button>*/}
              {/*)}*/}
              <Typography
                variant={"h6"}
                sx={{
                  fontSize: "24px",
                  fontWeight: "500",
                  lineHeight: "normal",
                  maxWidth: "293px",
                }}
              >
                Первые 14 дней бесплатно
              </Typography>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "400",
                  lineHeight: "21px",
                  m: "20px 0 30px",
                  color: "#fff",
                }}
              >
                Пробуйте, экспериментируйте, а наша отзывчивая техническая
                поддержка поможет и проконсультирует в любой момент, больше вы
                не останетесь 1 на 1 со своими проблемами.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "30px",
                  p: "20px 0 20px",
                }}
              >
                {/*{!isMobile && (*/}
                {/*    <Button*/}
                {/*        sx={{*/}
                {/*            width: "278px",*/}
                {/*            height: "44px",*/}
                {/*            borderRadius: "8px",*/}
                {/*            border: "1px solid #FFF",*/}
                {/*            color: "white",*/}
                {/*            background: "transparent",*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        Все возможности сервиса*/}
                {/*    </Button>*/}
                {/*)}*/}

                {/*<Button*/}
                {/*  variant={"contained"}*/}
                {/*  sx={{*/}
                {/*    width: "180px",*/}
                {/*    height: "44px",*/}
                {/*  }}*/}
                {/*>*/}
                {/*  Создать квиз*/}
                {/*</Button>*/}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </SectionStyled>
  );
}

import { Quiz } from "./quiz";

export interface EditQuizRequest {
  /** id of question for update */
  id: number;
  /** set true for storing fingerprints */
  fp: boolean;
  /** set true for allow to repeat quiz after passing */
  rep: boolean;
  /** set true for store unfinished passing */
  note_prevented: boolean;
  /** set true if we should send passing result on every passing */
  mailing: boolean;
  /** set true if we allow only one user quiz passing */
  uniq: boolean;
  /** new name of the quiz */
  name?: string;
  /** new descriptions of the quiz */
  desc?: string;
  /** new config of the quiz */
  conf?: string;
  /** new status. only draft,template,stop,start allowed */
  status?: string;
  /** max amount of quiz passing */
  limit: number;
  /** max time of quiz passing */
  due_to: number;
  /** max time to pass quiz */
  time_of_passing: number;
  /** allow to pause quiz to user */
  pausable: boolean;
  /** count of questions */
  questions_count?: number;
  /** set true if squiz realize group functionality */
  super?: boolean;
  /** group of new quiz */
  group_id?: number;
}

export interface EditQuizResponse {
  /** id of new version of question */
  updated: number;
}

export function quizToEditQuizRequest(quiz: Quiz): EditQuizRequest {
  return {
    id: quiz.backendId,
    fp: quiz.fingerprinting,
    rep: quiz.repeatable,
    note_prevented: quiz.note_prevented,
    mailing: quiz.mail_notifications,
    uniq: quiz.unique_answers,
    name: quiz.name,
    desc: quiz.description,
    conf: JSON.stringify(quiz.config),
    status: quiz.status,
    limit: quiz.limit,
    due_to: quiz.due_to,
    time_of_passing: quiz.time_of_passing,
    pausable: quiz.pausable,
    questions_count: quiz.questions_count,
    super: quiz.super,
    group_id: quiz.group_id,
  };
}

import { Box } from "@mui/material";

export const RequestsTariffIcon = () => {
  return (
    <Box>
      <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="36" height="36" rx="6" fill="#2B2C39" />
        <circle
          cx="3.40426"
          cy="3.40426"
          r="3.40426"
          transform="matrix(-1 0 0 1 21.625 10.5)"
          stroke="#944FEE"
          strokeWidth="1.5"
        />
        <path
          d="M12.2578 22.3565C12.2578 21.6242 12.7181 20.9711 13.4077 20.7248V20.7248C16.5166 19.6145 19.9139 19.6145 23.0228 20.7248V20.7248C23.7124 20.9711 24.1727 21.6242 24.1727 22.3565V23.4761C24.1727 24.4866 23.2776 25.2629 22.2772 25.12L21.9437 25.0724C19.4706 24.7191 16.9599 24.7191 14.4869 25.0724L14.1533 25.12C13.1529 25.2629 12.2578 24.4866 12.2578 23.4761V22.3565Z"
          stroke="#944FEE"
          strokeWidth="1.5"
        />
        <path
          d="M23.3174 17.4042C24.7947 17.4042 25.9922 16.2067 25.9922 14.7295C25.9922 13.2522 24.7947 12.0547 23.3174 12.0547"
          stroke="#944FEE"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M26.2471 23.5075L26.5092 23.5449C27.2952 23.6572 27.9985 23.0473 27.9985 22.2533V21.3736C27.9985 20.7983 27.6368 20.2851 27.095 20.0916C26.5546 19.8985 26.003 19.7482 25.4453 19.6406"
          stroke="#944FEE"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M12.6826 17.4042C11.2053 17.4042 10.0078 16.2067 10.0078 14.7295C10.0078 13.2522 11.2053 12.0547 12.6826 12.0547"
          stroke="#944FEE"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M9.75289 23.5075L9.49082 23.5449C8.70476 23.6572 8.0015 23.0473 8.0015 22.2533V21.3736C8.0015 20.7983 8.36316 20.2851 8.90496 20.0916C9.44541 19.8985 9.99696 19.7482 10.5547 19.6406"
          stroke="#944FEE"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    </Box>
  );
};

import { Button, SxProps, Theme } from "@mui/material";
import { Link } from "react-router-dom";
interface Props {
  variant: string;
  color?: string;
  sx?: SxProps<Theme>;
}

export const ToTariffsButton = () => {
  return (
    <Link to="/tariffs">
      <Button variant={"contained"} sx={{ height: "34px", fontSize: "14px" }}>
        Пополнить
      </Button>
    </Link>
  );
};

import { useEffect, useState } from "react";
import { useCurrentQuiz } from "@root/quizes/hooks";
import { useUiTools } from "@root/uiTools/store";
import { updateModalInfoWhyCantCreate } from "@root/uiTools/actions";
import { updateQuiz } from "@root/quizes/actions";
import { Button, Box } from "@mui/material";
import { BackButtonIcon } from "@icons/BackButtonIcon";

export const ButtonRecallQuiz = () => {
  const quiz = useCurrentQuiz();
  const { whyCantCreatePublic, canCreatePublic } = useUiTools();

  const [buttonText, setButtonText] = useState("обработка...");
  useEffect(() => {
    if (!canCreatePublic) setButtonText("Опубликовать");
  }, [canCreatePublic]);
  useEffect(() => {
    if (quiz && buttonText === "обработка...")
      setButtonText(quiz?.status === "stop" ? "Опубликовать" : "Отозвать");
  }, [quiz]);

  const handleClickStatusQuiz = () => {
    if (Object.keys(whyCantCreatePublic).length === 0) {
      if (buttonText === "Опубликовать") {
        setButtonText("Опубликовано");
        setTimeout(() => {
          setButtonText("Отозвать");
        }, 1500);
      } else {
        setButtonText("Опубликовать");
      }

      updateQuiz(quiz?.id, (state) => {
        state.status = quiz?.status === "start" ? "stop" : "start";
      });
    } else {
      updateModalInfoWhyCantCreate(true);
    }
  };

  return (
    <Button
      data-cy="publish-button"
      variant="contained"
      sx={{
        fontSize: "14px",
        lineHeight: "18px",
        height: "34px",
        background: buttonText === "Опубликовано" ? "#FA5B0E" : "#7E2AEA",
        p: "0 18px",
        minWidth: "100px",
      }}
      onClick={handleClickStatusQuiz}
    >
      {buttonText === "Отозвать" ? (
        <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
          {buttonText} <BackButtonIcon />
        </Box>
      ) : (
        buttonText
      )}
    </Button>
  );
};

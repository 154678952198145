import { Box } from "@mui/material";

export const TrialTariffIcon = () => {
  return (
    <Box>
      <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="36" height="36" rx="6" fill="white" />
        <path d="M18 12.1694V13.8361" stroke="#7E2AEA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M18 22.1694V23.8361" stroke="#7E2AEA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M18 28C23.5228 28 28 23.5228 28 18C28 12.4772 23.5228 8 18 8C12.4772 8 8 12.4772 8 18C8 23.5228 12.4772 28 18 28Z"
          stroke="#7E2AEA"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.4974 22.1639H19.2474C19.7999 22.1639 20.3298 21.9444 20.7205 21.5537C21.1112 21.163 21.3307 20.6331 21.3307 20.0806C21.3307 19.528 21.1112 18.9981 20.7205 18.6074C20.3298 18.2167 19.7999 17.9972 19.2474 17.9972H16.7474C16.1949 17.9972 15.665 17.7777 15.2743 17.387C14.8836 16.9963 14.6641 16.4664 14.6641 15.9139C14.6641 15.3614 14.8836 14.8315 15.2743 14.4408C15.665 14.0501 16.1949 13.8306 16.7474 13.8306H20.4974"
          stroke="#7E2AEA"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};

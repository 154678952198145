import { Box } from "@mui/material";

interface Props {
  color?: string;
}

export default function ({ color }: Props) {
  return (
    <Box
      sx={{
        height: "36px",
        width: "36px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="36" height="36" rx="6" fill="#7E2AEA" />
        <path
          d="M21.6923 19.1797H25.3846C25.5478 19.1797 25.7044 19.2321 25.8198 19.3255C25.9352 19.4188 26 19.5454 26 19.6775V23.6597C26 23.7917 25.9352 23.9183 25.8198 24.0117C25.7044 24.105 25.5478 24.1575 25.3846 24.1575H10.6154C10.4522 24.1575 10.2956 24.105 10.1802 24.0117C10.0648 23.9183 10 23.7917 10 23.6597V19.6775C10 19.5454 10.0648 19.4188 10.1802 19.3255C10.2956 19.2321 10.4522 19.1797 10.6154 19.1797H14.3077"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18 19.2444V10"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.3086 13.6923L18.0009 10L21.6932 13.6923"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.5599 22.4713C23.9791 22.4713 24.319 22.1315 24.319 21.7122C24.319 21.293 23.9791 20.9531 23.5599 20.9531C23.1406 20.9531 22.8008 21.293 22.8008 21.7122C22.8008 22.1315 23.1406 22.4713 23.5599 22.4713Z"
          fill="white"
        />
      </svg>
    </Box>
  );
}

import { FC, SVGProps } from "react";

export const TestingIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    {...props}
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.25 14.1641H3.75C2.7835 14.1641 2 14.9849 2 15.9974V19.6641C2 20.6766 2.7835 21.4974 3.75 21.4974H7.25C8.2165 21.4974 9 20.6766 9 19.6641V15.9974C9 14.9849 8.2165 14.1641 7.25 14.1641Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.25 2.5H3.75C2.7835 2.5 2 3.32081 2 4.33333V8C2 9.01252 2.7835 9.83333 3.75 9.83333H7.25C8.2165 9.83333 9 9.01252 9 8V4.33333C9 3.32081 8.2165 2.5 7.25 2.5Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.1992 6.10156H22.1992"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.1992 17.8984H22.1992"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 6.125L5.28571 7.25L7 5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import { Box, Fade, Typography } from "@mui/material";
import { FC } from "react";

interface Iprops {
  isOpen: boolean;
  header: string;
  image: string;
  text: string;
}

export const DesktopQuizCard: FC<Iprops> = ({
  isOpen,
  header,
  image,
  text,
}) => {
  return (
    <Fade
      style={{ display: isOpen ? "flex" : "none" }}
      timeout={1000}
      in={isOpen}
      unmountOnExit
    >
      <Box
        sx={{
          width: "100%",
          minHeight: "462px",
          backgroundColor: "#ffffff",
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          padding: "22px 22px 22px 50px",
          borderRadius: "12px",
          marginTop: "60px",
          boxSizing: "border-box",

          boxShadow:
            "0px 6.6501px 5.32008px 0px rgba(126, 42, 234, 0.03), 0px 12.52155px 10.01724px 0px rgba(126, 42, 234, 0.04), 0px 22.33631px 17.86905px 0px rgba(126, 42, 234, 0.04)",
        }}
      >
        <Box
          sx={{
            height: "70%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            paddingTop: "26px",
          }}
        >
          <Typography
            variant="h6"
            fontSize={"36px"}
            lineHeight={"42.66px"}
            maxWidth={"491px"}
          >
            {header}
          </Typography>
          <Box
            sx={{
              maxWidth: "416px",
              marginTop: "19px",
            }}
          >
            <Typography fontSize={"18px"} lineHeight={"21px"} color={"#4D4D4D"}>
              {text}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            maxWidth: "550px",
          }}
        >
          <Box
            component={"img"}
            src={image}
            sx={{
              width: "100%",
              height: "418px",
              backgroundColor: "#c8dade",
              borderRadius: "12px",
            }}
          />
        </Box>
      </Box>
    </Fade>
  );
};

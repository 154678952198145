import { Box, Switch, useTheme, Typography } from "@mui/material";

interface Props {
  openBranchingPage: boolean;
  setOpenBranchingPage: (a: boolean) => void;
  hideText?: boolean;
}

export const PanelSwitchQuestionListGraph = ({
  openBranchingPage,
  setOpenBranchingPage,
  hideText = false,
}: Props) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "15px",
        padding: "18px",
        background: "#fff",
        borderRadius: "12px",
        boxShadow: "0px 10px 30px #e7e7e7",
      }}
    >
      <Switch
        checked={openBranchingPage}
        onChange={(e) => setOpenBranchingPage(e.target.checked)}
        sx={{
          width: 50,
          height: 30,
          padding: 0,
          "& .MuiSwitch-switchBase": {
            padding: 0,
            margin: "2px",
            transitionDuration: "300ms",
            "&.Mui-checked": {
              transform: "translateX(20px)",
              color: theme.palette.brightPurple.main,
              "& + .MuiSwitch-track": {
                backgroundColor: "#E8DCF9",
                opacity: 1,
                border: 0,
              },
              "&.Mui-disabled + .MuiSwitch-track": { opacity: 0.5 },
            },
            "&.Mui-disabled .MuiSwitch-thumb": {
              color:
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[600],
            },
            "&.Mui-disabled + .MuiSwitch-track": {
              opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
            },
          },
          "& .MuiSwitch-thumb": {
            boxSizing: "border-box",
            width: 25,
            height: 25,
          },
          "& .MuiSwitch-track": {
            borderRadius: 13,
            backgroundColor:
              theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
            opacity: 1,
            transition: theme.transitions.create(["background-color"], {
              duration: 500,
            }),
          },
        }}
      />
      <Box>
        <Typography
          sx={{
            fontWeight: "bold",
            color: "#4D4D4D",
            whiteSpace: "nowrap",
          }}
        >
          Логика ветвления
        </Typography>
        {!hideText && (
          <Typography sx={{ color: "#4D4D4D", fontSize: "12px" }}>
            Настройте связи между вопросами
          </Typography>
        )}
      </Box>
    </Box>
  );
};

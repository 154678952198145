import { useTheme } from "@mui/material";

interface Props {
  width?: number;
}

export default function CloseBold({ width }: Props) {
  const theme = useTheme();

  return (
    <svg
      width="35"
      height="33"
      viewBox="0 0 35 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Close">
        <g id="Rectangle 57" opacity="0.3" filter="url(#filter0_d_4080_12482)">
          <rect x="6" y="4" width="24" height="24" rx="12" fill="#9A9AAF" />
        </g>
        <g id="Group 331">
          <path
            id="Vector 586"
            d="M22.8516 10.9517L12.9521 20.8512"
            stroke="#FDFDFF"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector 587"
            d="M22.8516 20.8462L12.9521 10.9467"
            stroke="#FDFDFF"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_4080_12482"
          x="0"
          y="0"
          width="36"
          height="36"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="1"
            operator="dilate"
            in="SourceAlpha"
            result="effect1_dropShadow_4080_12482"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.416562 0 0 0 0 0.452406 0 0 0 0 0.775 0 0 0 0.18 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4080_12482"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4080_12482"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

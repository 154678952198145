import { makeRequest } from "@frontend/kitui";

import { parseAxiosError } from "@utils/parse-error";

import type { Discount } from "@model/discounts";

const API_URL = `${process.env.REACT_APP_DOMAIN}/price/discount`;

export const getDiscounts = async (
  userId: string,
): Promise<[Discount[] | null, string?]> => {
  try {
    const { Discounts } = await makeRequest<never, { Discounts: Discount[] }>({
      method: "GET",
      url: `${API_URL}/user/${userId}`,
    });

    return [Discounts];
  } catch (nativeError) {
    const [error] = parseAxiosError(nativeError);

    return [null, `Не удалось получить скидки. ${error}`];
  }
};

import { Box, useTheme } from "@mui/material";

interface CheckboxIconProps {
  checked?: boolean;
  color?: string;
  isRounded?: boolean;
}

export default function CheckboxIcon({
  checked = false,
  color = "#7E2AEA",
  isRounded,
}: CheckboxIconProps) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        height: "24px",
        width: "24px",
        borderRadius: isRounded ? "50%" : "6px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: checked ? color : "#F2F3F7",
        border: `1px solid #9A9AAF`,
      }}
    >
      {checked && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="15"
          viewBox="0 0 25 18"
          fill="none"
        >
          <path
            d="M2 9L10 16.5L22.5 1.5"
            stroke="#ffffff"
            strokeWidth="4"
            strokeLinecap="round"
          />
        </svg>
      )}
    </Box>
  );
}

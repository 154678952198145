import { makeRequest } from "@api/makeRequest";

import { parseAxiosError } from "@utils/parse-error";

const API_URL = `${process.env.REACT_APP_DOMAIN}/feedback`;

type SendContactFormBody = {
  contact: string;
  whoami: string;
};

export const sendContactFormRequest = async (
  body: SendContactFormBody,
): Promise<[unknown | null, string?, number?]> => {
  try {
    const sendContactFormResponse = await makeRequest<
      SendContactFormBody,
      unknown
    >({
      method: "POST",
      url: `${API_URL}/callme`,
      body,
    });

    return [sendContactFormResponse];
  } catch (nativeError) {
    const [error, status] = parseAxiosError(nativeError);

    return [null, `Не удалось отправить контакты. ${error}`, status];
  }
};

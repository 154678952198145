import { Box } from "@mui/material";

interface Props {
  color?: string;
}

export default function ({ color }: Props) {
  return (
    <Box
      sx={{
        height: "36px",
        width: "36px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="36" height="36" rx="6" fill="#7E2AEA" />
        <path
          d="M16.9408 21.9531H10.1112C9.93977 21.9531 9.80078 22.0325 9.80078 22.1305V25.3235C9.80078 25.4215 9.93977 25.5009 10.1112 25.5009H16.9408C17.1122 25.5009 17.2512 25.4215 17.2512 25.3235V22.1305C17.2512 22.0325 17.1122 21.9531 16.9408 21.9531Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="11.6243" cy="23.7728" r="0.757156" fill="white" />
        <path
          d="M16.9408 16.2266H10.1112C9.93977 16.2266 9.80078 16.306 9.80078 16.404V19.597C9.80078 19.695 9.93977 19.7744 10.1112 19.7744H16.9408C17.1122 19.7744 17.2512 19.695 17.2512 19.597V16.404C17.2512 16.306 17.1122 16.2266 16.9408 16.2266Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="11.6243" cy="18.0462" r="0.757156" fill="white" />
        <path
          d="M16.9408 10.5H10.1112C9.93977 10.5 9.80078 10.5794 9.80078 10.6774V13.8704C9.80078 13.9684 9.93977 14.0478 10.1112 14.0478H16.9408C17.1122 14.0478 17.2512 13.9684 17.2512 13.8704V10.6774C17.2512 10.5794 17.1122 10.5 16.9408 10.5Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="11.6243" cy="12.3197" r="0.757156" fill="white" />
        <path
          d="M26.2297 10.5H19.7253C19.5621 10.5 19.4297 10.8358 19.4297 11.25V24.75C19.4297 25.1642 19.5621 25.5 19.7253 25.5H26.2297C26.3929 25.5 26.5253 25.1642 26.5253 24.75V11.25C26.5253 10.8358 26.3929 10.5 26.2297 10.5Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="21.097" cy="12.3197" r="0.757156" fill="white" />
      </svg>
    </Box>
  );
}

import {
  Box,
  Slide,
  Typography,
  useMediaQuery,
  useTheme,
  Zoom,
} from "@mui/material";
import { FC, ReactNode, useRef, useState } from "react";

interface Iprops {
  header: string;
  image: string;
  text: string;
  textIcon: string;
  icon: ReactNode;
}

export const MobileQuizCard: FC<Iprops> = ({
  header,
  image,
  text,
  icon,
  textIcon,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLElement>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(600));

  const handleChange = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <Box
      ref={containerRef}
      onClick={handleChange}
      sx={{
        width: "100%",
        minHeight: isOpen ? "462px" : "auto",
        backgroundColor: "#ffffff",
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        padding: isMobile ? "15px" : "20px",
        borderRadius: "12px",
        boxSizing: "border-box",
        boxShadow:
          "0px 6.6501px 5.32008px 0px rgba(126, 42, 234, 0.03), 0px 12.52155px 10.01724px 0px rgba(126, 42, 234, 0.04), 0px 22.33631px 17.86905px 0px rgba(126, 42, 234, 0.04)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "15px",
        }}
      >
        <Box
          sx={{
            borderRadius: "8px",
            width: "36px",
            height: "36px",
            background: "#7E2AEA",
            display: " flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {icon}
        </Box>
        <Typography>{textIcon}</Typography>
      </Box>
      {isOpen && (
        <Box
          sx={{
            height: "70%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            mt: "14px",
          }}
        >
          <Box>
            <Typography
              variant="h6"
              fontSize={isMobile ? "24px" : "36px"}
              lineHeight={isMobile ? "normal" : "42.66px"}
              maxWidth={"491px"}
            >
              {header}
            </Typography>
            <Box
              sx={{
                maxWidth: "100%",
                marginTop: "20px",
              }}
            >
              <Typography
                fontSize={"18px"}
                lineHeight={"21px"}
                color={"#4D4D4D"}
              >
                {text}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              maxWidth: "100%",
              marginTop: "30px",
            }}
          >
            <Box
              component={"img"}
              src={image}
              sx={{
                width: "100%",
                height: isMobile ? "228px" : "671px",
                backgroundColor: "#c8dade",
                borderRadius: "12px",
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

import {
  Breakpoint,
  Container,
  SxProps,
  Theme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { ElementType } from "react";

interface Props {
  component?: ElementType;
  outerContainerSx?: SxProps<Theme>;
  sx?: SxProps<Theme>;
  maxWidth?: false | Breakpoint;
  children?: React.ReactNode;
}

export default function SectionWrapper({
  component,
  outerContainerSx: sx,
  sx: innerSx,
  children,
  maxWidth,
}: Props) {
  const theme = useTheme();
  const matchMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Container
      component={component || "div"}
      maxWidth={false}
      disableGutters
      sx={sx}
    >
      <Container
        disableGutters
        maxWidth={maxWidth}
        sx={{
          px: matchMd ? "20px" : "0px",
          ...innerSx,
        }}
      >
        {children}
      </Container>
    </Container>
  );
}

import { useTheme } from "@mui/material";

interface Props {
  width: number;
  color?: string;
}

export default function QuizLogo({ width, color = "#151515" }: Props) {
  const theme = useTheme();

  return (
    <svg
      style={{ minWidth: width }}
      width={width}
      viewBox="0 0 149 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3258_71703)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.5091 2.75764C15.4332 2.05385 11.2415 8.58037 7.34253 13.2981C3.92083 17.4383 1.37308 22.1148 1.07715 27.4802C0.762314 33.1885 1.79779 39.1334 5.68668 43.32C9.69244 47.6324 15.6929 50.3572 21.5091 49.4762C26.8423 48.6684 29.2022 42.8117 33.1728 39.1568C37.4842 35.1882 45.5165 33.3425 45.4051 27.4802C45.2935 21.609 36.8162 20.4598 32.7372 16.2397C28.5122 11.8685 27.5447 3.45678 21.5091 2.75764Z"
          fill="#7E2AEA"
        />
        <circle cx="36.626" cy="47.2429" r="3.3526" fill="#7E2AEA" />
        <circle cx="33.2733" cy="10.0479" r="1.27718" fill="#7E2AEA" />
        <path
          d="M53.705 26.1072C53.3326 22.4867 51.6293 19.1334 48.9253 16.6973C46.2212 14.2612 42.709 12.9158 39.0694 12.9218C38.5526 12.9221 38.0363 12.9491 37.5223 13.0028C33.9035 13.3828 30.5535 15.0893 28.1185 17.7933C25.6835 20.4973 24.3361 24.0071 24.3359 27.6458V27.6458V48.6801H30.6462V39.7405C33.1134 41.457 36.0481 42.3747 39.0536 42.3698C39.5704 42.3696 40.0867 42.3426 40.6007 42.2888C42.5237 42.0867 44.3881 41.5078 46.0874 40.5852C47.7867 39.6626 49.2876 38.4142 50.5045 36.9115C51.7213 35.4088 52.6303 33.6811 53.1794 31.8271C53.7286 29.9731 53.9072 28.0291 53.705 26.1061V26.1072ZM45.6005 32.9433C44.9074 33.8044 44.0502 34.5194 43.0788 35.0469C42.1074 35.5744 41.0409 35.9039 39.9413 36.0164C39.6464 36.047 39.3501 36.0625 39.0536 36.0627C37.1452 36.0609 35.2941 35.4104 33.8041 34.218C32.3142 33.0256 31.2738 31.362 30.8539 29.5004C30.434 27.6388 30.6594 25.6897 31.4931 23.9731C32.3269 22.2564 33.7195 20.8743 35.4423 20.0534C37.1652 19.2325 39.1159 19.0217 40.9743 19.4556C42.8327 19.8895 44.4884 20.9423 45.6696 22.4412C46.8509 23.9401 47.4875 25.796 47.4749 27.7044C47.4623 29.6127 46.8014 31.4601 45.6005 32.9433Z"
          fill={color}
        />
        <path
          d="M70.1665 12.9218C69.6497 12.922 69.1333 12.9491 68.6194 13.0028C64.8697 13.3977 61.4144 15.216 58.9656 18.083C56.5168 20.95 55.2612 24.6472 55.4575 28.4125C55.6538 32.1779 57.2872 35.7244 60.0209 38.3212C62.7545 40.9179 66.3803 42.367 70.1507 42.3698C70.6674 42.3696 71.1838 42.3426 71.6978 42.2888C74.4013 42.001 76.973 40.9725 79.1295 39.3167C81.286 37.6609 82.9436 35.442 83.9197 32.9044H76.7271L76.6976 32.9412C75.7763 34.08 74.5737 34.9585 73.2086 35.4899C71.8436 36.0212 70.3635 36.187 68.9147 35.9708C67.4659 35.7546 66.0987 35.1639 64.9483 34.2572C63.7979 33.3505 62.9041 32.1591 62.3554 30.8009H84.5466C85.0138 28.6512 84.9941 26.4241 84.489 24.2829C83.9839 22.1417 83.0061 20.1407 81.6273 18.4264C80.2485 16.7121 78.5037 15.328 76.5206 14.3756C74.5375 13.4231 72.3664 12.9264 70.1665 12.9218V12.9218ZM62.3554 24.4907C62.9298 23.0767 63.8772 21.8452 65.0964 20.9272C66.3157 20.0093 67.7612 19.4394 69.2788 19.2784C69.5737 19.2477 69.87 19.2323 70.1665 19.2321C71.8425 19.2293 73.481 19.7288 74.8705 20.6661C76.2599 21.6034 77.3367 22.9355 77.9618 24.4907H62.3554Z"
          fill={color}
        />
        <path
          d="M100.135 12.9218C97.7229 12.918 95.357 13.5812 93.2986 14.8381V12.9218H86.9883V42.3698H93.2986V26.0683C93.2986 24.2552 94.0188 22.5164 95.3008 21.2344C96.5829 19.9523 98.3216 19.2321 100.135 19.2321C101.948 19.2321 103.687 19.9523 104.969 21.2344C106.251 22.5164 106.971 24.2552 106.971 26.0683V42.3698H113.281V26.0683C113.281 22.5816 111.896 19.2378 109.431 16.7723C106.965 14.3069 103.621 12.9218 100.135 12.9218Z"
          fill={color}
        />
        <path
          d="M144.833 29.5273V12.9218H138.523V15.5511C136.056 13.834 133.121 12.9161 130.116 12.9218C129.599 12.9221 129.083 12.9491 128.569 13.0028C124.819 13.3977 121.364 15.2161 118.915 18.083C116.466 20.95 115.21 24.6472 115.407 28.4126C115.603 32.1779 117.236 35.7244 119.97 38.3212C122.704 40.918 126.329 42.3671 130.1 42.3698C130.617 42.3696 131.133 42.3426 131.647 42.2888C135.314 41.9135 138.705 40.1663 141.139 37.3973C143.03 39.9275 145.769 41.6925 148.854 42.3698V35.7545C147.657 35.2122 146.641 34.3366 145.928 33.2323C145.215 32.128 144.835 30.8418 144.833 29.5273ZM136.647 32.9412C135.954 33.8021 135.096 34.517 134.125 35.0445C133.154 35.572 132.087 35.9016 130.988 36.0143C130.693 36.0449 130.396 36.0604 130.1 36.0606C127.944 36.0605 125.87 35.2328 124.307 33.7484C122.744 32.2639 121.81 30.2359 121.698 28.0829C121.586 25.9299 122.305 23.8161 123.707 22.1778C125.108 20.5395 127.085 19.5016 129.229 19.2784C129.524 19.2477 129.82 19.2323 130.116 19.2321C131.704 19.2335 133.26 19.6845 134.603 20.5331C135.946 21.3816 137.021 22.593 137.704 24.0271C138.388 25.4613 138.651 27.0595 138.464 28.637C138.277 30.2145 137.647 31.7067 136.647 32.9412Z"
          fill={color}
        />
        <path
          d="M123.338 57.5645V54.1748C123.106 54.4753 122.816 54.7144 122.469 54.8921C122.122 55.0656 121.747 55.1523 121.345 55.1523C120.579 55.1523 119.949 54.8646 119.454 54.2891C118.87 53.6162 118.578 52.7043 118.578 51.5532C118.578 50.4699 118.851 49.6257 119.396 49.0205C119.947 48.4111 120.628 48.1064 121.44 48.1064C121.889 48.1064 122.276 48.2017 122.602 48.3921C122.932 48.5825 123.224 48.8703 123.478 49.2554V48.2588H125.122V57.5645H123.338ZM123.396 51.5469C123.396 50.8571 123.254 50.3451 122.97 50.0107C122.691 49.6722 122.34 49.5029 121.917 49.5029C121.485 49.5029 121.123 49.6743 120.831 50.0171C120.543 50.3599 120.399 50.9036 120.399 51.6484C120.399 52.389 120.539 52.9243 120.818 53.2544C121.098 53.5802 121.443 53.7432 121.853 53.7432C122.264 53.7432 122.623 53.5591 122.932 53.1909C123.241 52.8228 123.396 52.2747 123.396 51.5469ZM131.317 55V53.9907C131.072 54.3504 130.748 54.634 130.346 54.8413C129.948 55.0487 129.527 55.1523 129.083 55.1523C128.63 55.1523 128.224 55.0529 127.864 54.854C127.505 54.6551 127.244 54.3758 127.083 54.0161C126.923 53.6564 126.842 53.1592 126.842 52.5244V48.2588H128.626V51.3564C128.626 52.3044 128.658 52.8862 128.721 53.1021C128.789 53.3136 128.91 53.4829 129.083 53.6099C129.257 53.7326 129.477 53.7939 129.743 53.7939C130.048 53.7939 130.321 53.7114 130.562 53.5464C130.803 53.3771 130.968 53.1698 131.057 52.9243C131.146 52.6746 131.19 52.0674 131.19 51.1025V48.2588H132.974V55H131.317ZM134.828 47.3447V45.6943H136.611V47.3447H134.828ZM134.828 55V48.2588H136.611V55H134.828ZM137.729 55V53.6099L140.255 50.709C140.67 50.235 140.976 49.8986 141.175 49.6997C140.968 49.7124 140.695 49.7209 140.356 49.7251L137.976 49.7378V48.2588H143.549V49.522L140.972 52.4927L140.064 53.4766C140.56 53.4469 140.866 53.4321 140.985 53.4321H143.746V55H137.729Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3258_71703">
          <rect width="149" height="57.6774" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

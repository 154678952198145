import { FC, SVGProps } from "react";

export const PieСhartIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    {...props}
    height="1em"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 1V11.8621M11 11.8621L19.5714 7.51724M11 11.8621L18.1429 19.1034M11 22C5.47715 22 1 17.4611 1 11.8621C1 6.26304 5.47715 1.72414 11 1.72414C16.5228 1.72414 21 6.26304 21 11.8621C21 17.4611 16.5228 22 11 22Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
);

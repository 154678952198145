import { Quiz } from "@model/quiz/quiz";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

export type QuizStore = {
  quizes: Quiz[];
  editQuizId: number | null;
  currentStep: number;
};

const initialState: QuizStore = {
  quizes: [],
  editQuizId: null,
  currentStep: 0,
};

export const useQuizStore = create<QuizStore>()(
  persist(
    devtools(() => initialState, {
      name: "QuizStore",
      enabled: process.env.NODE_ENV === "development",
      trace: process.env.NODE_ENV === "development",
    }),
    {
      name: "QuizStore",
      partialize: (state) => ({
        editQuizId: state.editQuizId,
        currentStep: state.currentStep,
      }),
    },
  ),
);

export const clearQuizData = () => useQuizStore.setState({ ...initialState });
